import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './commission.module.scss';
import { Split } from 'components/Split';
import { Advisor } from 'components/Advisor';

const Commission = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabs = [
    { label: 'Commission Split Percentage' },
    { label: 'Client Advisor Relationship' },
  ];

  const renderContent = () => {
    switch (tab) {
      case 0:
        return (
          <Split handleTabChange={handleTabChange} tab={tab} tabs={tabs} />
        );
      case 1:
        return (
          <Advisor handleTabChange={handleTabChange} tab={tab} tabs={tabs} />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Commission;
