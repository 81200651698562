import React from 'react';
import styles from './App.module.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from 'pages/signup/signup';
import Login from 'pages/login/login';
// import ForgotPassword from 'pages/forgotPassword/forgotPassword';
// import NewPassword from 'pages/newPassword/newPassword';
// import PasswordReset from 'pages/passwordReset/passwordReset';
// import RequestPage from 'pages/request/request';
// import IdGeneratedPage from 'pages/idGenerated/idGenerated';
// import Verify from 'pages/verify/verify';
import { SideBar } from 'components/Sidebar';
import Dashboard from 'pages/dashboard/dashboard';
import { Header } from 'components/Header';
import Upload from 'pages/upload/upload';
import Errors from 'pages/errors/error';
import Commission from 'pages/commission/commission';
import EmployeePayout from 'pages/employeePayout/employeePayout';
import PaymentTracking from 'pages/paymentTracking/paymentTracking';
import Profile from 'pages/profile/profile';
import Support from 'pages/support/support';
import Mails from 'pages/mails/mails';
import PaymentRequests from 'pages/paymentRequests/PaymentRequests';
import RefundRequests from 'pages/refundRequests/RefundRequests';
import ProtectedRoute from 'components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/request" element={<RequestPage />} />
        <Route path="/id-generated" element={<IdGeneratedPage />} />
        <Route path="/verify" element={<Verify />} /> */}

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Dashboard />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Upload />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/error-logs"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Errors />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/commission-splits"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Commission />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/advisors-payout"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <EmployeePayout />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payments-tracking"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <PaymentTracking />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-settings"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Profile />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Support />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mail-box"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Mails />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-request"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <PaymentRequests />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/refund-request"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <RefundRequests />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
