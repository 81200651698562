import React, { useState } from 'react';
import { PAYMENT_DATA } from 'utils/requests';
import { paymentColumns } from 'utils/requests';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import EarlyPaymentRequest from 'components/EarlyPaymentRequest/EarlyPaymentRequest';
import { GenericTable } from 'components/common/GenericTable';

const PayRequest = ({ handleTabChange, tab }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <GenericTable
        title="Early Payment Request"
        data={PAYMENT_DATA}
        columns={paymentColumns(handleOpen)}
        pageConfig={{
          page: 0,
          setPage: () => {},
          rowsPerPage: 10,
          setRowsPerPage: () => {},
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
      />
      <ModalContainer open={open}>
        <EarlyPaymentRequest handleClose={handleClose} />
      </ModalContainer>
    </>
  );
};

export default PayRequest;
