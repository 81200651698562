import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import CsvIcon from 'assets/icons/csv.svg';
import styles from './uploadFile.module.scss';
import { Upload } from 'assets/svgs';
import { Title } from 'components/common/Title';

const UploadFile = ({ onUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(50);

  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    setUploading(true);

    setTimeout(() => {
      setUploadProgress(100);
      setUploading(false);
      onUploadComplete();
    }, 2000);
  };

  return (
    <Box className={styles.uploadContainer}>
      <Title title={'Upload File'} />
      <br />
      <Typography variant="body2" className={styles.subtitle}>
        Upload and attach files to this project.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControlLabel control={<Checkbox />} label="Monthly Report" />
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Weekly Report"
        />
      </Box>
      <Box className={styles.fileUploadBox}>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-upload"
        />
        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
          <Upload />
          <Typography variant="body1" color="#000000">
            Choose a file or drag & drop it here
          </Typography>
          <Typography variant="body2" color="#687182">
            Maximum file size 50MB
          </Typography>
        </label>
      </Box>
      {file && (
        <Box className={styles.fileDetailsBox}>
          <Box className={styles.fileIcon}>
            <img src={CsvIcon} alt="CSV Icon" />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1" className={styles.fileName}>
              {file.name}
            </Typography>
            <Typography variant="body2" className={styles.fileSize}>
              {`${Math.round(file.size / 1024)} KB of 120 KB • Uploading...`}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              className={styles.progressBar}
            />
          </Box>
          <IconButton
            onClick={() => setFile(null)}
            className={styles.cancelButton}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )}
      <Button
        variant="contained"
        fullWidth
        startIcon={
          uploading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CloudUploadIcon />
          )
        }
        onClick={handleUpload}
        disabled={!file || uploading}
        className={styles.uploadButton}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
    </Box>
  );
};

export default UploadFile;
