import React from 'react';

const dataUpload = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37496 11.1106V14.9306C9.37496 15.2756 9.65496 15.5556 9.99996 15.5556C10.345 15.5556 10.625 15.2756 10.625 14.9306V11.1106L12.0631 12.5169C12.31 12.7575 12.7056 12.7531 12.9468 12.5062C13.1881 12.26 13.1837 11.8637 12.9368 11.6225L10.8737 9.60625C10.3881 9.13125 9.61184 9.13125 9.12621 9.60625L7.06309 11.6225C6.81621 11.8637 6.81184 12.26 7.05309 12.5062C7.29434 12.7531 7.68996 12.7575 7.93684 12.5169L9.37496 11.1106Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 7.48187V6.75C17.5 5.71438 16.6606 4.875 15.625 4.875H9.1225C8.79625 4.30938 8.26563 3.90063 7.65125 3.72313C7.6225 3.71501 7.59438 3.70876 7.565 3.70438L7.045 3.63126C7.01625 3.62688 6.98688 3.625 6.9575 3.625C5.86625 3.625 4.24187 3.625 3.125 3.625C2.6275 3.625 2.15063 3.8225 1.79938 4.17437C1.4475 4.52562 1.25 5.0025 1.25 5.5V15.5C1.25 15.9975 1.4475 16.4744 1.79938 16.8256C2.15063 17.1775 2.6275 17.375 3.125 17.375H16.875C17.9106 17.375 18.75 16.5356 18.75 15.5V9.25C18.75 8.43375 18.2281 7.73874 17.5 7.48187ZM7.34437 4.93624C7.66 5.03937 7.92812 5.26563 8.07937 5.57376L9.06437 7.5775C9.38 8.21875 10.0325 8.625 10.7469 8.625H16.875C17.22 8.625 17.5 8.905 17.5 9.25V15.5C17.5 15.845 17.22 16.125 16.875 16.125H3.125C2.95938 16.125 2.8 16.0594 2.68312 15.9419C2.56562 15.825 2.5 15.6656 2.5 15.5V5.5C2.5 5.33438 2.56562 5.175 2.68312 5.05812C2.8 4.94062 2.95938 4.875 3.125 4.875H6.91375L7.34188 4.93562L7.34437 4.93624ZM9.74313 6.125L10.1863 7.02563C10.2913 7.23938 10.5087 7.375 10.7469 7.375H16.25V6.75C16.25 6.405 15.97 6.125 15.625 6.125H9.74313Z"
        fill={color}
      />
    </svg>
  );
};

export default dataUpload;
