import React from 'react';
import { motion } from 'framer-motion';

const SettingsAnimations = ({
  children,
  type = 'fadeInUp',
  delay = 0,
  duration = 0.5,
  ...rest
}) => {
  const variants = {
    fadeInUp: {
      hidden: { opacity: 0, y: 20 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: duration,
          delay: delay,
        },
      },
    },
    fadeInLeft: {
      hidden: { opacity: 0, x: -20 },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: duration,
          delay: delay,
        },
      },
    },
    fadeInRight: {
      hidden: { opacity: 0, x: 20 },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: duration,
          delay: delay,
        },
      },
    },
  };

  return (
    <motion.div
      variants={variants[type]}
      initial="hidden"
      animate="visible"
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default SettingsAnimations;
