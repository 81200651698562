import authInterceptor from './Interceptors/authInterceptor';

export const loginService = async payload => {
  try {
    const response = await authInterceptor.post(`/api/user/login`, payload);
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Login failed');
  }
};

export const registerService = async payload => {
  try {
    const response = await authInterceptor.post(`/api/user/register`, payload);
    return response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Registration failed');
  }
};

export const verifyOtpService = async payload => {
  const response = await authInterceptor.post('/api/user/verify-otp', payload);
  return response;
};

export const resendOtpService = async payload => {
  const response = await authInterceptor.post(
    '/api/user/forget-password',
    payload
  );
  return response;
};

export const changePasswordService = async payload => {
  try {
    const response = await authInterceptor.post(
      `/api/user/set-password`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Password change failed');
  }
};
