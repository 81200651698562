import React from 'react';

const signout = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.609 11.3764L13.4604 8.04929C13.5966 7.89395 13.6663 7.69785 13.6664 7.50072C13.6665 7.36583 13.634 7.23046 13.5679 7.10735C13.5385 7.05248 13.5027 7.00035 13.4604 6.95215L10.609 3.62504C10.3095 3.27559 9.78338 3.23509 9.43392 3.53459C9.08447 3.83409 9.04397 4.36017 9.34347 4.70962L11.0213 6.66733L4.56745 6.66733C4.10721 6.66733 3.73411 7.04042 3.73411 7.50066C3.73411 7.9609 4.10721 8.334 4.56745 8.334L11.0214 8.334L9.34347 10.2918C9.04397 10.6413 9.08447 11.1673 9.43392 11.4668C9.78338 11.7663 10.3095 11.7258 10.609 11.3764ZM5.33305 2.50065C5.79328 2.50065 6.16638 2.87375 6.16638 3.33398L6.16638 4.58398C6.16638 5.04422 6.53948 5.41732 6.99971 5.41732C7.45995 5.41732 7.83305 5.04422 7.83305 4.58398L7.83305 3.33398C7.83305 1.95327 6.71376 0.833984 5.33305 0.833984L2.83305 0.833984C1.45234 0.833984 0.333048 1.95327 0.333048 3.33398L0.333047 11.6673C0.333047 13.048 1.45233 14.1673 2.83305 14.1673L5.33305 14.1673C6.71376 14.1673 7.83305 13.048 7.83305 11.6673L7.83305 10.4173C7.83305 9.95708 7.45995 9.58398 6.99971 9.58398C6.53948 9.58398 6.16638 9.95708 6.16638 10.4173L6.16638 11.6673C6.16638 12.1276 5.79328 12.5007 5.33305 12.5007L2.83305 12.5007C2.37281 12.5007 1.99971 12.1276 1.99971 11.6673L1.99971 3.33398C1.99971 2.87375 2.37281 2.50065 2.83305 2.50065L5.33305 2.50065Z"
        fill="white"
      />
    </svg>
  );
};

export default signout;
