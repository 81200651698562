import React from 'react';
import { Box, Typography } from '@mui/material';
import Animation from 'utils/Animation';
import { line } from 'utils/styles';
import styles from './title.module.scss';

const Title = ({ title }) => {
  return (
    <Animation>
      <Typography
        variant="h5"
        fontWeight="bold"
        color="#003466"
        className={styles.title}
      >
        {title}
        <Animation delay={0.3}>
          <Box sx={line} />
        </Animation>
      </Typography>
    </Animation>
  );
};

export default Title;
