import { Button } from '@mui/material';
import { Visibility, Download } from '@mui/icons-material';

export const REPORTS_DATA = [
  {
    id: 1,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 2,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 3,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 4,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 5,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 6,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
  {
    id: 7,
    advisorName: 'John Watt',
    advisorID: '5698239256',
    date: '24-07-24',
  },
];

export const REPORTS_COLUMNS = [
  {
    headerName: 'Advisor Name',
    field: 'advisorName',
  },
  {
    headerName: 'Advisor ID',
    field: 'advisorID',
  },
  {
    headerName: 'Date',
    field: 'date',
  },
  {
    headerName: 'View',
    renderCell: row => (
      <Button
        variant="text"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Visibility sx={{ color: '#687182' }} />
      </Button>
    ),
  },
  {
    headerName: 'Download',
    renderCell: row => (
      <Button
        variant="text"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Download sx={{ color: '#687182' }} />
      </Button>
    ),
  },
];
