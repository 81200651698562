import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import Animation from 'utils/Animation';

const Head = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Animation>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ fontWeight: 'bold' }}
            >
              Plan
            </Typography>
          </Animation>
        </TableCell>
        <TableCell>
          <Animation delay={0.1}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ fontWeight: 'bold' }}
            >
              Popularity
            </Typography>
          </Animation>
        </TableCell>
        <TableCell align="right">
          <Animation delay={0.2}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ fontWeight: 'bold' }}
            >
              Commission
            </Typography>
          </Animation>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default Head;
