import React from 'react';
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@mui/material';
import styles from './colsSettings.module.scss';

const ColsSettings = ({
  columns,
  selectedCols,
  onToggleCol,
  anchorEl,
  handleClose,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? 'cols-settings-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className={styles.colsSettingsContainer}>
        <List className={styles.scrollableList}>
          {columns.map((col, index) => (
            <ListItem key={index} className={styles.listItem}>
              <Checkbox
                checked={selectedCols.includes(col.field)}
                onChange={() => onToggleCol(col.field)}
                sx={{
                  color: selectedCols.includes(col.field)
                    ? '#003466'
                    : '#CCCCCC',
                }}
                className={styles.checkbox}
              />
              <ListItemText
                primary={col.headerName}
                className={styles.listItemText}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
};

export default ColsSettings;
