import React from 'react';
import { Grid } from '@mui/material';
import SignupForm from 'components/Signup/SignupForm/SignupForm';
import SignUpInfo from 'components/Signup/SignUpInfo/SignUpInfo';
import styles from './signup.module.scss';

const Signup = () => {
  return (
    <Grid container className={styles.signupContainer}>
      <Grid item xs={12} md={6} className={styles.leftSection}>
        <SignUpInfo />
      </Grid>
      <Grid item xs={12} md={6} className={styles.rightSection}>
        <SignupForm />
      </Grid>
    </Grid>
  );
};

export default Signup;
