import React from 'react';

const toValidate = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_264_2040)">
        <path
          d="M2.61377 2.05518L7.67348 2.67647L7.05219 7.73612"
          stroke="#718EBF"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M14.5 28.1506C6.96108 28.1506 0.849609 22.0391 0.849609 14.5002C0.849609 9.4482 3.59407 5.03714 7.6735 2.67676"
          stroke="#718EBF"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M26.3864 26.9446L21.3267 26.3233L21.948 21.2637"
          stroke="#718EBF"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M14.5 0.849609C22.0389 0.849609 28.1504 6.96108 28.1504 14.5C28.1504 19.552 25.4059 23.9631 21.3265 26.3234"
          stroke="#718EBF"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M17.5037 12.6978L13.8991 16.3024L11.4961 13.8993"
          stroke="#718EBF"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M14.5 21.2969C18.2538 21.2969 21.2969 18.2538 21.2969 14.5C21.2969 10.7462 18.2538 7.70312 14.5 7.70312C10.7462 7.70312 7.70312 10.7462 7.70312 14.5C7.70312 18.2538 10.7462 21.2969 14.5 21.2969Z"
          stroke="#718EBF"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_264_2040">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default toValidate;
