import { Box, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { getSalesBoxStyles } from 'utils/styles';
import { motion } from 'framer-motion';
import styles from '../plans.module.scss';
import { getProgressBarContainerStyles } from 'utils/styles';
import { getProgressBarBackgroundStyles } from 'utils/styles';
import { getAnimatedProgressBarStyles } from 'utils/styles';

const Row = ({ plan, index }) => {
  return (
    <TableRow key={index}>
      <TableCell>
        <Typography
          variant="subtitle2"
          color="textPrimary"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {plan.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={getProgressBarContainerStyles(index)}>
          <Box sx={getProgressBarBackgroundStyles(index)} />
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${plan.popularity}%` }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={getAnimatedProgressBarStyles(index)}
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box className={styles.salesBox} sx={getSalesBoxStyles(index)}>
          {plan.sales}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default Row;
