import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SettingsAnimations from 'components/common/Animations/SettingsAnimations';
import styles from './password.module.scss';
import { Title } from 'components/common/Title';
import { Tabs } from 'components/common/Tabs';

const Password = ({ tab, tabs, handleTabChange }) => {
  const [showPassword, setShowPassword] = React.useState({
    newPassword: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = field => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <Box className={styles.passwordContainer}>
      <SettingsAnimations type="fadeInUp" duration={0.6}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Title title="Profile Settings" />
        </Box>
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      </SettingsAnimations>
      <SettingsAnimations type="fadeInUp" duration={0.8}>
        <Box className={styles.formContainer}>
          <Grid container spacing={3} className={styles.formFields}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Current Password"
                variant="outlined"
                className={styles.textField}
                value="Current Password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New Password"
                variant="outlined"
                className={styles.textField}
                type={showPassword.newPassword ? 'text' : 'password'}
                value="New Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('newPassword')}
                        edge="end"
                      >
                        {showPassword.newPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                variant="outlined"
                className={styles.textField}
                type={showPassword.confirmPassword ? 'text' : 'password'}
                value="Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickShowPassword('confirmPassword')
                        }
                        edge="end"
                      >
                        {showPassword.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box className={styles.actionButtons}>
            <SettingsAnimations type="fadeInLeft" delay={0.8}>
              <Button variant="outlined" className={styles.cancelButton}>
                Cancel
              </Button>
            </SettingsAnimations>
            <SettingsAnimations type="fadeInRight" delay={0.9}>
              <Button variant="contained" className={styles.saveButton}>
                Save Changes
              </Button>
            </SettingsAnimations>
          </Box>
        </Box>
      </SettingsAnimations>
    </Box>
  );
};

export default Password;
