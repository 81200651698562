import React, { useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { PAYMENT_DATA } from 'utils/requests';
import { paymentColumns } from 'utils/requests';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import RefundPaymentRequest from 'components/RefundPaymentRequest/RefundPaymentRequest';

const Tracking = ({ handleTabChange, tab }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <GenericTable
        title="Refund Request"
        data={PAYMENT_DATA}
        columns={paymentColumns(handleOpen)}
        pageConfig={{
          page: 0,
          setPage: () => {},
          rowsPerPage: 10,
          setRowsPerPage: () => {},
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
      />
      <ModalContainer open={open}>
        <RefundPaymentRequest handleClose={handleClose} />
      </ModalContainer>
    </>
  );
};

export default Tracking;
