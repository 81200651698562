import React from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { SALES_DATA } from 'utils/salesData';
import { SALES_COLUMNS } from 'utils/salesData';

const Validation = ({ handleTabChange, tab, tabs }) => {
  return (
    <GenericTable
      title="Error Logs"
      data={SALES_DATA}
      columns={SALES_COLUMNS}
      pageConfig={{
        page: 0,
        setPage: () => {},
        rowsPerPage: 10,
        setRowsPerPage: () => {},
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      isEdit={true}
    />
  );
};

export default Validation;
