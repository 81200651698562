import React from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { COMMISSION_COLUMNS } from 'utils/commission';
import { COMMISSION_DATA } from 'utils/commission';

const Split = ({ handleTabChange, tab, tabs }) => {
  return (
    <GenericTable
      title="Commission Split"
      data={COMMISSION_DATA}
      columns={COMMISSION_COLUMNS}
      pageConfig={{
        page: 0,
        setPage: () => {},
        rowsPerPage: 10,
        setRowsPerPage: () => {},
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      isEdit={true}
    />
  );
};

export default Split;
