import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const authInterceptor = axios.create({
  baseURL: API_URL,
});

authInterceptor.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default authInterceptor;
