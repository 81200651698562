import React from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from '../Mails/mails.module.scss';

const Item = ({
  email,
  isSelected,
  handleToggle,
  handleDelete,
  index,
  handleView,
}) => {
  return (
    <ListItem
      key={index}
      className={`${styles.mailItem} ${isSelected ? styles.selected : ''}`}
      onClick={() => handleView(index)}
    >
      <ListItemAvatar>
        <Checkbox
          edge="start"
          checked={isSelected}
          tabIndex={-1}
          disableRipple
          className={styles.checkbox}
          onClick={() => handleToggle(index)}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box className={styles.mailContent}>
            <Typography className={styles.senderName}>
              {email.sender}
            </Typography>
            <Typography className={styles.subject}>{email.subject}</Typography>
            <Typography className={styles.message}>{email.message}</Typography>
            {isSelected ? (
              <IconButton
                edge="end"
                className={styles.deleteIcon}
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              <Typography className={styles.time}>{email.time}</Typography>
            )}
          </Box>
        }
      />
    </ListItem>
  );
};

export default Item;
