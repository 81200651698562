import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './faq.module.scss';
import { Title } from 'components/common/Title';
import SettingsAnimations from 'components/common/Animations/SettingsAnimations';

const Faq = ({ faqs }) => {
  return (
    <Box className={styles.faqContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Title title={'FAQ’s'} />
      </Box>
      {faqs.map((faq, index) => (
        <SettingsAnimations
          type="fadeInUp"
          duration={0.5}
          delay={index * 0.2}
          key={index}
        >
          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography className={styles.accordionTitle}>
                {index + 1}. {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={styles.accordionContent}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </SettingsAnimations>
      ))}
    </Box>
  );
};

export default Faq;
