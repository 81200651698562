import React from 'react';

const mail = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_475_6051)">
        <path
          d="M1.40627 14.125H14.5938C15.3676 14.125 16 13.4953 16 12.7188V4.28125C16 3.50438 15.3672 2.875 14.5938 2.875H1.40627C0.632453 2.875 1.52588e-05 3.50466 1.52588e-05 4.28125V12.7188C1.52588e-05 13.4955 0.632765 14.125 1.40627 14.125ZM1.6222 13.1875C2.07686 12.7313 7.41736 7.37344 7.63677 7.15331C7.82002 6.9695 8.17992 6.96938 8.36327 7.15331L14.3778 13.1875H1.6222ZM15.0625 4.45359V12.5464L11.0292 8.5L15.0625 4.45359ZM14.3778 3.8125L10.3674 7.836L9.02723 6.49147C8.47805 5.9405 7.52176 5.94072 6.97276 6.49147L5.63261 7.83597L1.6222 3.8125H14.3778ZM0.937515 4.45359L4.9708 8.5L0.937515 12.5464V4.45359Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_475_6051">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="matrix(-1 0 0 -1 16 16.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default mail;
