import React from 'react';

const cancelledIcon = () => {
  return (
    <svg
      width="29"
      height="23"
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5625 9.375C20.2646 9.375 18.9958 9.75988 17.9166 10.481C16.8374 11.2021 15.9962 12.227 15.4995 13.4261C15.0028 14.6253 14.8729 15.9448 15.1261 17.2178C15.3793 18.4908 16.0043 19.6601 16.9221 20.5779C17.8399 21.4957 19.0092 22.1207 20.2822 22.3739C21.5552 22.6271 22.8747 22.4972 24.0739 22.0005C25.273 21.5038 26.2979 20.6626 27.019 19.5834C27.7401 18.5042 28.125 17.2354 28.125 15.9375C28.125 14.197 27.4336 12.5278 26.2029 11.2971C24.9722 10.0664 23.303 9.375 21.5625 9.375ZM21.5625 20.625C20.6354 20.625 19.7291 20.3501 18.9583 19.835C18.1874 19.3199 17.5866 18.5879 17.2318 17.7313C16.877 16.8748 16.7842 15.9323 16.9651 15.023C17.1459 14.1137 17.5924 13.2785 18.2479 12.6229C18.9035 11.9674 19.7387 11.5209 20.648 11.3401C21.5573 11.1592 22.4998 11.252 23.3563 11.6068C24.2129 11.9616 24.9449 12.5624 25.46 13.3333C25.9751 14.1041 26.25 15.0104 26.25 15.9375C26.25 17.1807 25.7561 18.373 24.8771 19.2521C23.998 20.1311 22.8057 20.625 21.5625 20.625Z"
        fill="#AD46DD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.0668 13.4337C23.891 13.2579 23.6526 13.1592 23.404 13.1592C23.1554 13.1592 22.917 13.2579 22.7412 13.4337L21.5627 14.6121L20.3843 13.4337C20.2978 13.3441 20.1944 13.2727 20.08 13.2236C19.9656 13.1744 19.8426 13.1486 19.7181 13.1475C19.5936 13.1464 19.4702 13.1701 19.355 13.2173C19.2397 13.2644 19.1351 13.334 19.047 13.422C18.959 13.5101 18.8894 13.6147 18.8423 13.73C18.7951 13.8452 18.7714 13.9686 18.7725 14.0931C18.7736 14.2176 18.7994 14.3406 18.8486 14.455C18.8977 14.5694 18.9691 14.6728 19.0587 14.7593L20.2371 15.9377L19.0587 17.1162C18.9691 17.2026 18.8977 17.3061 18.8486 17.4205C18.7994 17.5349 18.7736 17.6579 18.7725 17.7824C18.7714 17.9068 18.7951 18.0303 18.8423 18.1455C18.8894 18.2607 18.959 18.3654 19.047 18.4534C19.1351 18.5414 19.2397 18.611 19.355 18.6582C19.4702 18.7053 19.5936 18.729 19.7181 18.728C19.8426 18.7269 19.9656 18.701 20.08 18.6519C20.1944 18.6028 20.2978 18.5313 20.3843 18.4418L21.5627 17.2634L22.7412 18.4418C22.918 18.6126 23.1548 18.7071 23.4006 18.7049C23.6464 18.7028 23.8816 18.6042 24.0554 18.4304C24.2292 18.2566 24.3278 18.0214 24.3299 17.7756C24.3321 17.5298 24.2376 17.293 24.0668 17.1162L22.8884 15.9377L24.0668 14.7593C24.2425 14.5835 24.3413 14.3451 24.3413 14.0965C24.3413 13.8479 24.2425 13.6095 24.0668 13.4337Z"
        fill="#AD46DD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.375 0H3.75C2.75544 0 1.80161 0.395088 1.09835 1.09835C0.395088 1.80161 0 2.75544 0 3.75V15C0 15.9946 0.395088 16.9484 1.09835 17.6516C1.80161 18.3549 2.75544 18.75 3.75 18.75H13.125C13.3736 18.75 13.6121 18.6512 13.7879 18.4754C13.9637 18.2996 14.0625 18.0611 14.0625 17.8125C14.0625 17.5639 13.9637 17.3254 13.7879 17.1496C13.6121 16.9738 13.3736 16.875 13.125 16.875H3.75C3.25272 16.875 2.77581 16.6775 2.42417 16.3258C2.07254 15.9742 1.875 15.4973 1.875 15V7.5H19.6875C19.9361 7.5 20.1746 7.40123 20.3504 7.22541C20.5262 7.0496 20.625 6.81114 20.625 6.5625C20.625 6.31386 20.5262 6.0754 20.3504 5.89959C20.1746 5.72377 19.9361 5.625 19.6875 5.625H1.875V3.75C1.875 3.25272 2.07254 2.77581 2.42417 2.42417C2.77581 2.07254 3.25272 1.875 3.75 1.875H24.375C24.8723 1.875 25.3492 2.07254 25.7008 2.42417C26.0525 2.77581 26.25 3.25272 26.25 3.75V5.625H23.4375C23.1889 5.625 22.9504 5.72377 22.7746 5.89959C22.5988 6.0754 22.5 6.31386 22.5 6.5625C22.5 6.81114 22.5988 7.0496 22.7746 7.22541C22.9504 7.40123 23.1889 7.5 23.4375 7.5H26.25V9.375C26.25 9.62364 26.3488 9.8621 26.5246 10.0379C26.7004 10.2137 26.9389 10.3125 27.1875 10.3125C27.4361 10.3125 27.6746 10.2137 27.8504 10.0379C28.0262 9.8621 28.125 9.62364 28.125 9.375V3.75C28.125 2.75544 27.7299 1.80161 27.0266 1.09835C26.3234 0.395088 25.3696 0 24.375 0Z"
        fill="#AD46DD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.6875 13.125C4.43886 13.125 4.2004 13.2238 4.02459 13.3996C3.84877 13.5754 3.75 13.8139 3.75 14.0625C3.75 14.3111 3.84877 14.5496 4.02459 14.7254C4.2004 14.9012 4.43886 15 4.6875 15H8.4375C8.68614 15 8.9246 14.9012 9.10041 14.7254C9.27623 14.5496 9.375 14.3111 9.375 14.0625C9.375 13.8139 9.27623 13.5754 9.10041 13.3996C8.9246 13.2238 8.68614 13.125 8.4375 13.125H4.6875Z"
        fill="#AD46DD"
      />
    </svg>
  );
};

export default cancelledIcon;
