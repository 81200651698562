// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
// import { authActions } from '_redux/slices/auth.slice';

const ProtectedRoute = ({ children }) => {
  // const isAuthenticated = useSelector(authActions.selectors.getIsAuthenticated);

  // if (!isAuthenticated) {
  //   return <Navigate to="/login" />;
  // }

  return children;
};

export default ProtectedRoute;
