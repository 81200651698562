import React from 'react';

const lateIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_264_2062)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.6875 15.3745C18.0309 15.3745 14.25 19.1554 14.25 23.812C14.25 28.4686 18.0309 32.2495 22.6875 32.2495C27.3441 32.2495 31.125 28.4686 31.125 23.812C31.125 19.1554 27.3441 15.3745 22.6875 15.3745ZM22.6875 17.2495C26.3091 17.2495 29.25 20.1904 29.25 23.812C29.25 27.4336 26.3091 30.3745 22.6875 30.3745C19.0659 30.3745 16.125 27.4336 16.125 23.812C16.125 20.1904 19.0659 17.2495 22.6875 17.2495ZM21.75 20.0864C20.7131 20.2476 19.875 21.202 19.875 22.4058C19.875 23.7286 20.8866 24.7495 22.0622 24.7495H23.3128C23.5191 24.7495 23.625 24.9858 23.625 25.2183C23.625 25.4508 23.5191 25.687 23.3128 25.687H20.8125C20.295 25.687 19.875 26.107 19.875 26.6245C19.875 27.142 20.295 27.562 20.8125 27.562H21.75V28.4995C21.75 29.017 22.17 29.437 22.6875 29.437C23.205 29.437 23.625 29.017 23.625 28.4995V27.5376C24.6619 27.3764 25.5 26.422 25.5 25.2183C25.5 23.8954 24.4884 22.8745 23.3128 22.8745H22.0622C21.8559 22.8745 21.75 22.6383 21.75 22.4058C21.75 22.1733 21.8559 21.937 22.0622 21.937H24.5625C25.08 21.937 25.5 21.517 25.5 20.9995C25.5 20.482 25.08 20.062 24.5625 20.062H23.625V19.1245C23.625 18.607 23.205 18.187 22.6875 18.187C22.17 18.187 21.75 18.607 21.75 19.1245V20.0864ZM4.875 12.562H25.5V14.437C25.5 14.9545 25.92 15.3745 26.4375 15.3745C26.955 15.3745 27.375 14.9545 27.375 14.437V8.81201C27.375 7.25951 26.115 5.99951 24.5625 5.99951H5.8125C4.26 5.99951 3 7.25951 3 8.81201V20.062C3 21.6145 4.26 22.8745 5.8125 22.8745H12.375C12.8925 22.8745 13.3125 22.4545 13.3125 21.937C13.3125 21.4195 12.8925 20.9995 12.375 20.9995H5.8125C5.295 20.9995 4.875 20.5795 4.875 20.062V12.562ZM25.5 10.687V8.81201C25.5 8.29451 25.08 7.87451 24.5625 7.87451H5.8125C5.295 7.87451 4.875 8.29451 4.875 8.81201V10.687H25.5Z"
          fill="#00B69B"
        />
      </g>
      <defs>
        <clipPath id="clip0_264_2062">
          <rect
            width="32.8417"
            height="32.8417"
            fill="white"
            transform="translate(0.526367 0.724121)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default lateIcon;
