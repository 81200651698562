import React from 'react';

const epmPayout = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58329 8.41667H11.6666C11.8958 8.41667 12.0833 8.22917 12.0833 8C12.0833 7.77083 11.8958 7.58333 11.6666 7.58333H10.4166V7.16667C10.4166 6.9375 10.2291 6.75 9.99996 6.75C9.77079 6.75 9.58329 6.9375 9.58329 7.16667V7.58333C8.66246 7.58333 7.91663 8.32917 7.91663 9.25C7.91663 10.1708 8.66246 10.9167 9.58329 10.9167H10.4166C10.875 10.9167 11.25 11.2917 11.25 11.75C11.25 12.2083 10.875 12.5833 10.4166 12.5833H8.33329C8.10413 12.5833 7.91663 12.7708 7.91663 13C7.91663 13.2292 8.10413 13.4167 8.33329 13.4167H9.58329V13.8333C9.58329 14.0625 9.77079 14.25 9.99996 14.25C10.2291 14.25 10.4166 14.0625 10.4166 13.8333V13.4167C11.3375 13.4167 12.0833 12.6708 12.0833 11.75C12.0833 10.8292 11.3375 10.0833 10.4166 10.0833H9.58329C9.12496 10.0833 8.74996 9.70833 8.74996 9.25C8.74996 8.79167 9.12496 8.41667 9.58329 8.41667Z"
        fill={color}
      />
      <path
        d="M18.3333 5.0835H1.66667C1.4375 5.0835 1.25 5.271 1.25 5.50016V15.5002C1.25 15.7293 1.4375 15.9168 1.66667 15.9168H18.3333C18.5625 15.9168 18.75 15.7293 18.75 15.5002V5.50016C18.75 5.271 18.5625 5.0835 18.3333 5.0835ZM2.08333 15.0835V5.91683H8.00833C7.52917 6.12516 7.09167 6.4085 6.70417 6.75016H5C4.77083 6.75016 4.58333 6.93766 4.58333 7.16683C4.58333 7.85433 4.02083 8.41683 3.33333 8.41683C3.10417 8.41683 2.91667 8.60433 2.91667 8.8335V12.1668C2.91667 12.396 3.10417 12.5835 3.33333 12.5835C4.02083 12.5835 4.58333 13.146 4.58333 13.8335C4.58333 14.0627 4.77083 14.2502 5 14.2502H6.70417C7.09167 14.5918 7.52917 14.8752 8.00833 15.0835H2.08333ZM5.94583 7.5835C5.35417 8.40433 5 9.41266 5 10.5002C5 11.5877 5.35417 12.596 5.94583 13.4168H5.375C5.20833 12.6002 4.56667 11.9585 3.75 11.7918V9.2085C4.56667 9.04183 5.20833 8.40016 5.375 7.5835H5.94583ZM5.83333 10.5002C5.83333 8.20433 7.70417 6.3335 10 6.3335C12.2958 6.3335 14.1667 8.20433 14.1667 10.5002C14.1667 12.796 12.2958 14.6668 10 14.6668C7.70417 14.6668 5.83333 12.796 5.83333 10.5002ZM14.0542 7.5835H14.625C14.7917 8.40016 15.4333 9.04183 16.25 9.2085V11.7918C15.4333 11.9585 14.7917 12.6002 14.625 13.4168H14.0542C14.6458 12.596 15 11.5877 15 10.5002C15 9.41266 14.6458 8.40433 14.0542 7.5835ZM17.9167 15.0835H11.9917C12.4708 14.8752 12.9083 14.5918 13.2958 14.2502H15C15.2292 14.2502 15.4167 14.0627 15.4167 13.8335C15.4167 13.146 15.9792 12.5835 16.6667 12.5835C16.8958 12.5835 17.0833 12.396 17.0833 12.1668V8.8335C17.0833 8.60433 16.8958 8.41683 16.6667 8.41683C15.9792 8.41683 15.4167 7.85433 15.4167 7.16683C15.4167 6.93766 15.2292 6.75016 15 6.75016H13.2958C12.9083 6.4085 12.4708 6.12516 11.9917 5.91683H17.9167V15.0835Z"
        fill={color}
      />
    </svg>
  );
};

export default epmPayout;
