import React from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { PAYOUT_DATA } from 'utils/payout';
import { PAYOUT_COLUMNS } from 'utils/payout';

const Payout = ({ handleTabChange, tab }) => {
  return (
    <GenericTable
      title="Advisors’s Payout"
      data={PAYOUT_DATA}
      columns={PAYOUT_COLUMNS}
      pageConfig={{
        page: 0,
        setPage: () => {},
        rowsPerPage: 10,
        setRowsPerPage: () => {},
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={false}
      isEdit={true}
      isRefundRequest={true}
      isPayRequest={true}
    />
  );
};

export default Payout;
