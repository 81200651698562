import React from 'react';
import { Grid } from '@mui/material';
import LoginForm from 'components/Login/LoginForm/LoginForm';
import styles from './login.module.scss';
import { SignUpInfo } from 'components/Signup';

const Login = () => {
  return (
    <Grid container className={styles.loginContainer}>
      <Grid item xs={12} md={6} className={styles.leftSection}>
        <SignUpInfo />
      </Grid>
      <Grid item xs={12} md={6} className={styles.rightSection}>
        <LoginForm />
      </Grid>
    </Grid>
  );
};

export default Login;
