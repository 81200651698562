import React from 'react';

const splits = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4265 9.29918C12.7301 8.44242 11.934 7.75984 11.0601 7.26898C11.2032 7.09965 11.3162 6.90109 11.3891 6.6798C11.6753 5.80836 11.2573 4.7732 10.1904 4.43598L11.686 1.82797C11.8747 1.49883 11.7052 1.07961 11.3413 0.973828C10.2599 0.659414 9.13578 0.5 8 0.5C6.86422 0.5 5.74008 0.659414 4.65867 0.973828C4.29438 1.07973 4.12551 1.49922 4.31398 1.82797L5.80965 4.43602C4.74695 4.77152 4.32367 5.80516 4.61105 6.68027C4.68375 6.90098 4.79664 7.09941 4.9402 7.26883C4.06617 7.75969 3.27 8.44234 2.57352 9.29918C1.18129 11.012 0.382812 13.1724 0.382812 15.2266C0.382812 19.4879 3.78309 20.5 8 20.5C12.2199 20.5 15.6172 19.4862 15.6172 15.2266C15.6172 13.1724 14.8187 11.012 13.4265 9.29918ZM5.71469 1.91477C6.46223 1.7534 7.22805 1.67188 8 1.67188C8.77195 1.67188 9.53777 1.7534 10.2853 1.91477L8.99496 4.16484C8.33406 4.07258 7.66613 4.07262 7.00508 4.16488L5.71469 1.91477ZM6.0975 5.57406C7.1059 5.24328 8.1609 5.18059 9.18656 5.38562C9.18777 5.38586 9.18902 5.38609 9.19023 5.38633C9.4293 5.4343 9.66676 5.49672 9.90168 5.57379C10.2088 5.675 10.3766 6.00715 10.2759 6.31367C10.2027 6.53566 10.0096 6.68855 9.78234 6.71332C9.19812 6.53039 8.60223 6.4375 8 6.4375C7.39898 6.4375 6.80426 6.53 6.22117 6.71219C5.99043 6.68844 5.7968 6.53445 5.72426 6.31422C5.62344 6.00715 5.79125 5.67496 6.0975 5.57406ZM8 19.3359C3.42203 19.3359 1.55469 18.1398 1.55469 15.2266C1.55469 13.4632 2.27551 11.5237 3.48281 10.0384C4.75605 8.47199 6.36027 7.60938 8 7.60938C9.80492 7.60938 11.3836 8.64383 12.5172 10.0384C13.7245 11.5237 14.4453 13.4632 14.4453 15.2266C14.4453 18.1398 12.578 19.3359 8 19.3359Z"
        fill={color}
      />
    </svg>
  );
};

export default splits;
