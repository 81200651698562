import React from 'react';
import styles from './iconButtons.module.scss';
import NotificationsPopover from '../NotificationPopover/NotificationPopover';
import { IconButton } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';

const IconButtons = ({ handleClick }) => {
  return (
    <div className={styles.iconContainer}>
      <NotificationsPopover />
      <IconButton className={styles.iconButton} onClick={handleClick}>
        <MailIcon />
      </IconButton>
    </div>
  );
};

export default IconButtons;
