import { RefundIcon } from 'assets/svgs';
import {
  DasboardIcon,
  DataUploadIcon,
  SupportIcon,
  MailIcon,
  ErrorIcon,
  SplitsIcon,
  EpmPayoutIcon,
  ReportIcon,
  SettingsIcon,
} from 'assets/svgs';

const ROUTES = [
  {
    path: '/',
    name: 'Dashboard',
    icon: <DasboardIcon />,
  },
  {
    path: '/upload',
    name: 'Data Upload',
    icon: <DataUploadIcon />,
  },
  {
    path: '/error-logs',
    name: 'Error Log',
    icon: <ErrorIcon />,
  },
  {
    path: '/commission-splits',
    name: 'Commission Splits',
    icon: <SplitsIcon />,
  },
  {
    path: '/advisors-payout',
    name: "Advisor's Payout",
    icon: <EpmPayoutIcon />,
  },
  {
    path: '/payments-tracking',
    name: "Payment's Tracking",
    icon: <ReportIcon />,
  },
  {
    path: '/profile-settings',
    name: 'Profile Settings',
    icon: <SettingsIcon />,
  },
  {
    path: '/support',
    name: 'Support',
    icon: <SupportIcon />,
  },
  {
    path: '/mail-box',
    name: 'Mail Box',
    icon: <MailIcon />,
  },
  {
    path: '/payment-request',
    name: 'Payment Request',
    icon: <ReportIcon />,
  },
  {
    path: '/refund-request',
    name: 'Refund Request',
    icon: <RefundIcon />,
  },
];

export default ROUTES;
