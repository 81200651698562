import React from 'react';
import Grid from '@mui/material/Grid';
import StatItem from './StatItem/StatItem';
import { STATS_DATA } from 'utils';
import styles from './stats.module.scss';

const Stats = () => {
  return (
    <Grid container spacing={2}>
      {STATS_DATA.map((stat, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={2.4}
          key={index}
          className={styles.statsContainer}
        >
          <StatItem
            icon={stat.icon}
            label={stat.label}
            count={stat.count}
            backgroundColor={stat.backgroundColor}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Stats;
