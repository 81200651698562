import { COLORS } from './index';

export const selectStyles = {
  backgroundColor: '#f5f7fa',
  '& .css-sgoict-MuiCardHeader-action': {
    borderRadius: '1rem',
  },
  '& .MuiSelect-select': {
    color: '#707070',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const menuPropsStyles = {
  PaperProps: {
    sx: {
      borderRadius: '8px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 1) 100%)',
      backdropFilter: 'blur(10px)',
      '& .MuiMenuItem-root': {
        borderRadius: '8px',
        margin: '0.5rem 0.5rem',
        padding: '0.5rem 1rem',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
};

export const getProgressBarStyles = index => ({
  height: '8px',
  borderRadius: '5px',
  backgroundColor: COLORS[index]?.progressBarBackground,
  '& .MuiLinearProgress-bar': {
    backgroundColor: COLORS[index]?.color,
    borderRadius: '5px',
  },
});

export const getSalesBoxStyles = index => ({
  backgroundColor: COLORS[index]?.backgroundColor,
  color: COLORS[index]?.color,
  borderColor: COLORS[index]?.color,
});

export const getProgressBarContainerStyles = index => ({
  height: '8px',
  borderRadius: '5px',
  overflow: 'hidden',
  width: '100%',
  position: 'relative',
  backgroundColor: getProgressBarBackgroundStyles(index).backgroundColor,
});

export const getProgressBarBackgroundStyles = index => {
  const colors = [
    {
      backgroundColor: 'rgba(255, 107, 107, 0.2)',
    },
    {
      backgroundColor: 'rgba(107, 203, 119, 0.2)',
    },
    {
      backgroundColor: 'rgba(140, 103, 245, 0.2)',
    },
    {
      backgroundColor: 'rgba(245, 166, 35, 0.2)',
    },
  ];

  return {
    width: '100%',
    position: 'absolute',
    height: '100%',
    borderRadius: '5px',
    backgroundColor: colors[index]?.backgroundColor,
  };
};

export const getAnimatedProgressBarStyles = index => {
  const colors = [
    {
      color: '#FF6B6B',
    },
    {
      color: '#6BCB77',
    },
    {
      color: '#8C67F5',
    },
    {
      color: '#F5A623',
    },
  ];

  return {
    height: '100%',
    borderRadius: '5px',
    backgroundColor: colors[index]?.color,
    position: 'absolute',
    top: 0,
    left: 0,
  };
};

export const chip = row => {
  return {
    color: row.status === 'Approved' ? '#14804A' : '#FF5151',
    borderColor: row.status === 'Approved' ? '#14804A' : '#FF5151',
    border: 'none',
    backgroundColor: row.status === 'Approved' ? '#E1FCEF' : '#FFE6E6',
    borderRadius: '1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    width: '6rem',
  };
};

export const requestchip = row => {
  return {
    color:
      row.status === 'Approved'
        ? '#14804A'
        : row.status === 'Rejected'
          ? '#FF5151'
          : '#FFCE00',
    borderColor:
      row.status === 'Approved'
        ? '#14804A'
        : row.status === 'Rejected'
          ? '#FFE6E6'
          : '#FF5151',
    border: 'none',
    backgroundColor:
      row.status === 'Approved'
        ? '#E1FCEF'
        : row.status === 'Rejected'
          ? '#FFE6E6'
          : '#FFF6D1',
    borderRadius: '1rem',
    textTransform: 'none',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    width: '6rem',
  };
};

export const button = {
  textTransform: 'none',
  fontWeight: 'bold',
  color: '#687182',
  whiteSpace: 'nowrap',
};

export const line = {
  height: '4px',
  backgroundColor: '#FFD700',
  mt: '4px',
};

export const card = {
  boxShadow: 'none',
  borderRadius: '8px',
  height: '100%',
};

export const uploadButton = {
  backgroundColor: '#F4F7FC',
  paddingTop: '1rem',
  borderRadius: '0.5rem',
  border: 'solid 2px #F4F7FC',
  boxShadow: '0px 0px 2px 0px #00000014',
};

export const icon = { backgroundColor: 'white', borderRadius: 2 };
