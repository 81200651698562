import React from 'react';
import { Tabs, Tab } from '@mui/material';
import styles from './tabs.module.scss';

const CustomTabs = ({ tabs, value, onChange }) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      aria-label="Tabs"
      className={styles.customTabs}
      TabIndicatorProps={{ style: { display: 'none' } }}
      variant="scrollable"
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          className={value === index ? styles.activeTab : styles.tab}
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
