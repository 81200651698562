import React from 'react';
import { Modal, Box } from '@mui/material';
import styles from './modalContainer.module.scss';

const ModalContainer = ({ open, children }) => {
  return (
    <Modal open={open} className={styles.modal}>
      <Box className={styles.modalContent}>{children}</Box>
    </Modal>
  );
};

export default ModalContainer;
