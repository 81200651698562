import React from 'react';
import { Box, TextField, Typography, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import styles from './composeMail.module.scss';

const ComposeMail = ({ onClose }) => {
  return (
    <Box className={styles.composeMailContainer}>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.title}>
          Email
        </Typography>
        <IconButton onClick={onClose} className={styles.closeButton}>
          <Close />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <TextField
          fullWidth
          variant="outlined"
          label="From"
          value="joh@gmail.com"
          className={styles.inputField}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="To"
          value="sam@gmail.com"
          className={styles.inputField}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Subject"
          value="Lorem ipsum"
          className={styles.inputField}
        />

        <TextField
          fullWidth
          variant="outlined"
          label=""
          placeholder="Write your email content here..."
          multiline
          rows={6}
          className={styles.textArea}
        />
      </Box>

      <Box className={styles.footer}>
        <Box className={styles.iconsContainer}>
          <IconButton>
            <AttachFileIcon />
          </IconButton>
          <IconButton>
            <ImageIcon />
          </IconButton>
          <IconButton>
            <InsertEmoticonIcon />
          </IconButton>
          <IconButton>
            <LinkIcon />
          </IconButton>
          <IconButton>
            <LockIcon />
          </IconButton>
          <IconButton>
            <FormatAlignLeftIcon />
          </IconButton>
        </Box>
        <Button variant="contained" className={styles.submitButton}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ComposeMail;
