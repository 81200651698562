import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
} from '@mui/material';
import styles from './performance.module.scss';
import Animation from 'utils/Animation';
import { PERFORMANCE_DATA } from 'utils';
import Row from './Row/Row';

const Performance = ({ title, color }) => {
  return (
    <Card
      className={styles.performanceCard}
      sx={{ boxShadow: 'none', borderRadius: '0.6rem' }}
    >
      <CardHeader
        title={
          <Animation>
            <Typography variant="h5" fontWeight="bold" className={styles.title}>
              Top 3 {title} performing advisors's
            </Typography>
          </Animation>
        }
        subheader={
          <Animation delay={0.3}>
            <Typography
              variant="body2"
              color="textSecondary"
              className={styles.subTitle}
            >
              Performance Highlights
            </Typography>
          </Animation>
        }
        className={styles.cardHeader}
      />
      <Divider className={styles.divider} />
      <TableContainer>
        <Table>
          <TableBody>
            {PERFORMANCE_DATA.map((employee, index) => (
              <Row
                employee={employee}
                index={index}
                key={index}
                color={color}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Performance;
