export const FAQ_DATA = [
  {
    question: 'Lorem ipsum dolor sit amet consectetur.',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Tellus commodo dolor felis tempor leo duis ornare viverra.',
  },
  {
    question: 'Lorem ipsum dolor sit amet consectetur.',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Tellus commodo dolor felis tempor leo duis ornare viverra.',
  },
  {
    question: 'Lorem ipsum dolor sit amet consectetur.',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Tellus commodo dolor felis tempor leo duis ornare viverra.',
  },
  {
    question: 'Lorem ipsum dolor sit amet consectetur.',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Tellus commodo dolor felis tempor leo duis ornare viverra.',
  },
];
