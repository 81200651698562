import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AnimatePresence, motion } from 'framer-motion';
import ROUTES from 'utils/routes';
import styles from './sidebar.module.scss';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import { Logo } from 'assets/svgs';
import { SignoutIcon } from 'assets/svgs';

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: { width: 0, opacity: 0, transition: { duration: 0.5 } },
    show: { opacity: 1, width: 'auto', transition: { duration: 0.5 } },
  };

  return (
    <div className={styles.mainContainer}>
      <motion.div
        animate={{
          width: isOpen ? '240px' : '60px',
          transition: {
            duration: 0.5,
            type: 'spring',
            damping: 10,
          },
        }}
        className={styles.sidebar}
      >
        <div className={styles.topSection}>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className={styles.logo}
              >
                <Logo className={styles.logoImage} />
              </motion.div>
            )}
          </AnimatePresence>
          <IconButton onClick={toggle} className={styles.bars}>
            <MenuIcon />
          </IconButton>
        </div>
        <section className={styles.routes}>
          {ROUTES.map((route, index) => {
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                  key={index}
                />
              );
            }

            const isActive = location.pathname === route.path;

            return (
              <NavLink
                to={route.path}
                key={index}
                className={`${styles.link} ${isActive ? styles.active : ''} ${!isOpen ? styles.closed : ''}`}
              >
                <div className={styles.icon}>
                  {React.cloneElement(route.icon, {
                    color: isActive ? '#FFFFFF' : '#BBBBBB',
                  })}
                </div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className={styles.linkText}
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
        </section>
        <div className={styles.logoutSection}>
          <NavLink
            to="/logout"
            className={`${styles.link} ${!isOpen ? styles.closed : ''}`}
          >
            <div className={styles.icon}>
              <SignoutIcon />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className={styles.linkText}
                >
                  Sign Out
                </motion.div>
              )}
            </AnimatePresence>
          </NavLink>
        </div>
      </motion.div>
      <main>{children}</main>
    </div>
  );
};

export default SideBar;
