export const PAYOUT_DATA = [
  {
    id: 1,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 2,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 3,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 4,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 5,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 6,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 7,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 8,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
];

export const PAYOUT_COLUMNS = [
  { headerName: 'Advisors Name', field: 'employeeName' },
  { headerName: 'Period', field: 'period' },
  { headerName: 'Total Gross FCI', field: 'totalGrossFCI' },
  { headerName: 'Total Advisor Split', field: 'totalAdvisorSplit' },
  { headerName: 'Total Deduction', field: 'totalDeduction' },
  { headerName: 'Net Payout', field: 'netPayout' },
  { headerName: 'LG Share Margin', field: 'lgShareMargin' },
];
