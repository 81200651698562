import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import styles from './viewMail.module.scss';

const ViewMail = ({ onBack }) => {
  return (
    <Box className={styles.viewMailContainer}>
      <IconButton onClick={onBack} className={styles.backButton}>
        <ArrowBackIcon />
      </IconButton>
      <Box className={styles.header}>
        <Typography variant="h6" className={styles.subject}>
          Email Subject
        </Typography>
        <Typography variant="body2" className={styles.timestamp}>
          09:20pm June 04, 24
        </Typography>
      </Box>
      <Box className={styles.recipientInfo}>
        <Avatar
          src="https://i.pravatar.cc/300"
          alt="Recipient Avatar"
          className={styles.avatar}
        />
        <Box>
          <Typography className={styles.recipientName}>
            Recipient Name
          </Typography>
          <Typography variant="body2" className={styles.recipientEmail}>
            email@domain.com
          </Typography>
        </Box>
      </Box>
      <Box className={styles.messageContent}>
        <Typography variant="body1" className={styles.greeting}>
          Hello Thomas,
        </Typography>
        <Typography variant="body2" className={styles.messageBody}>
          Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui
          esse pariatur duis deserunt mollit dolore cillum minim tempor enim.
          Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate
          aute id deserunt nisi. Aliqua id fugiat nostrud irure ex duis ea quis
          id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore
          cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt
          sint deserunt ut voluptate aute id deserunt nisi.
        </Typography>
        <Typography variant="body2" className={styles.messageBody}>
          Looking forward and best regards,
        </Typography>
      </Box>
      <Box className={styles.actions}>
        <Button variant="outlined" startIcon={<ReplyIcon />}>
          Reply
        </Button>
        <Button variant="outlined" startIcon={<ForwardIcon />}>
          Forward
        </Button>
      </Box>
    </Box>
  );
};

export default ViewMail;
