import React, { useState } from 'react';
import { TextField, Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import styles from './forgotPassword.module.scss';
import { useDispatch } from 'react-redux';
import { RESEND_OTP } from '_redux/types/auth.types';

const ForgotPassword = ({ email, onSubmit, onClose }) => {
  const dispatch = useDispatch();
  const [enteredEmail, setEnteredEmail] = useState(email || '');

  const handleEmailSubmit = e => {
    e.preventDefault();
    dispatch({
      type: RESEND_OTP,
      payload: {
        email: enteredEmail,
        onSuccess: () => {
          onSubmit(enteredEmail);
        },
      },
    });
  };

  return (
    <Box className={styles.formContainer}>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <LockIcon className={styles.lockIcon} />
      <h2>Forgot Password?</h2>
      <form className={styles.form} onSubmit={handleEmailSubmit}>
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={enteredEmail}
          onChange={e => setEnteredEmail(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Reset Password
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPassword;
