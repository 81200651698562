import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './profile.module.scss';
import { Settings } from 'components/Settings';
import { Password } from 'components/Password';

const Profile = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabs = [{ label: 'General Settings' }, { label: 'Password Settings' }];

  const renderContent = () => {
    switch (tab) {
      case 0:
        return (
          <Settings handleTabChange={handleTabChange} tab={tab} tabs={tabs} />
        );
      case 1:
        return (
          <Password handleTabChange={handleTabChange} tab={tab} tabs={tabs} />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
