import React from 'react';

const error = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_476_5183)">
        <path
          d="M9.99975 14.2061C9.44061 14.2061 8.97192 14.6747 8.97192 15.2339C8.97192 15.793 9.44061 16.2617 9.99975 16.2617C10.5383 16.2617 11.0276 15.793 11.0029 15.2586C11.0276 14.6706 10.563 14.2061 9.99975 14.2061Z"
          fill={color}
        />
        <path
          d="M19.5133 17.8489C20.1588 16.7347 20.1629 15.4067 19.5215 14.2967L13.0832 3.14682C12.446 2.02443 11.2948 1.3584 10.0039 1.3584C8.71291 1.3584 7.56175 2.02854 6.92449 3.14271L0.477959 14.3049C-0.163405 15.4273 -0.159294 16.7635 0.490293 17.8776C1.13166 18.9795 2.27871 19.6414 3.56144 19.6414H16.4216C17.7085 19.6414 18.8637 18.9712 19.5133 17.8489ZM18.1155 17.043C17.7578 17.6597 17.1247 18.0256 16.4175 18.0256H3.55733C2.85841 18.0256 2.22938 17.668 1.87992 17.0636C1.52634 16.451 1.52223 15.7192 1.8758 15.1025L8.32234 3.94441C8.6718 3.33183 9.29672 2.97003 10.0039 2.97003C10.7069 2.97003 11.3359 3.33594 11.6854 3.94852L18.1278 15.1066C18.4732 15.7069 18.469 16.4305 18.1155 17.043Z"
          fill={color}
        />
        <path
          d="M9.7449 6.99093C9.25565 7.13072 8.95142 7.57474 8.95142 8.11332C8.97608 8.43811 8.99664 8.76702 9.02131 9.09181C9.0912 10.3293 9.16109 11.5422 9.23098 12.7797C9.25565 13.199 9.58045 13.5032 9.9998 13.5032C10.4192 13.5032 10.7481 13.1785 10.7686 12.755C10.7686 12.5001 10.7686 12.2657 10.7933 12.0067C10.8385 11.2132 10.8878 10.4198 10.9331 9.62628C10.9577 9.11237 11.003 8.59845 11.0276 8.08454C11.0276 7.89953 11.003 7.73508 10.9331 7.57063C10.7234 7.11016 10.2341 6.87581 9.7449 6.99093Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_476_5183">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default error;
