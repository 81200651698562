import React from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { ERRORS_DATA, ERRORS_COLUMNS } from 'utils/errorsData';

const Error = ({ handleTabChange, tab, tabs }) => {
  return (
    <GenericTable
      title="Error Logs"
      data={ERRORS_DATA}
      columns={ERRORS_COLUMNS}
      pageConfig={{
        page: 0,
        setPage: () => {},
        rowsPerPage: 10,
        setRowsPerPage: () => {},
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
    />
  );
};

export default Error;
