import React from 'react';

const refund = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 4.98546C9.94057 4.98546 11.5146 6.56027 11.5146 8.50005C11.5146 10.4398 9.94057 12.0146 8.00001 12.0146C6.05944 12.0146 4.48541 10.4398 4.48541 8.50005C4.48541 6.56027 6.05944 4.98546 8.00001 4.98546Z"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00002 1.97291C11.6025 1.97291 14.5271 4.89753 14.5271 8.5"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.50208 6.99336H7.7364C7.32721 6.99336 6.99583 7.32548 6.99583 7.73495V7.73545C6.99583 8.15742 7.33727 8.49961 7.75902 8.49961H8.25105C8.6678 8.49961 9.00418 8.83677 9.00418 9.25273C9.00418 9.6687 8.6678 10.0059 8.25105 10.0059H7.49793"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 6.99414V6.49208"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 10.0067V10.5088"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00001 2.97754L6.99582 1.97338L8.00001 0.969195"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00001 15.0273C4.39757 15.0273 1.47295 12.1027 1.47295 8.50025"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00002 14.0229L9.0042 15.0271L8.00002 16.0312"
        stroke={color}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default refund;
