import React from 'react';
import { CardHeader, Select, MenuItem, Typography } from '@mui/material';
import { selectStyles } from 'utils/styles';
import { menuPropsStyles } from 'utils/styles';
import Animation from 'utils/Animation';
import styles from './cardHeader.module.scss';

const _CardHeader = ({ seriesData, handleChangeSeries }) => {
  return (
    <CardHeader
      title={
        <Animation>
          <Typography variant="h5" fontWeight="bold" className={styles.title}>
            £37.5k
          </Typography>
        </Animation>
      }
      subheader={
        <Animation delay={0.3}>
          <Typography variant="body2" color="textSecondary">
            Total Commission Amount
          </Typography>
        </Animation>
      }
      action={
        <Animation>
          <Select
            value={seriesData}
            onChange={handleChangeSeries}
            size="small"
            sx={selectStyles}
            MenuProps={menuPropsStyles}
          >
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Yearly">Yearly</MenuItem>
          </Select>
        </Animation>
      }
    />
  );
};

export default _CardHeader;
