import React from 'react';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './searchBar.module.scss';

const SearchBar = () => {
  return (
    <div className={styles.searchContainer}>
      <InputBase
        placeholder="Search..."
        classes={{ root: styles.inputRoot, input: styles.inputInput }}
      />
      <IconButton type="submit" className={styles.searchIcon}>
        <SearchIcon />
      </IconButton>
    </div>
  );
};

export default SearchBar;
