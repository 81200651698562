import React from 'react';
import { Grid, Box } from '@mui/material';
import styles from './refundRequests.module.scss';
import { RefundRequest } from 'components/RefundRequest';

const RefundRequests = () => {
  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RefundRequest />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RefundRequests;
