import React, { useState } from 'react';
import { Grid, Box, Breadcrumbs, Typography, Link } from '@mui/material';
import styles from './upload.module.scss';
import { UploadData } from 'components/UploadData';
import { UploadFile } from 'components/UploadFile';
import { GenericTable } from 'components/common/GenericTable';
import { SALES_DATA, SALES_COLUMNS } from 'utils/salesData'; // Assuming you have this data and columns setup

const Upload = () => {
  const [step, setStep] = useState('uploadData');

  const handleUploadClick = () => {
    setStep('uploadFile');
  };

  const handleFileUploadComplete = () => {
    setStep('salesData');
  };

  const renderContent = () => {
    switch (step) {
      case 'uploadData':
        return <UploadData onUploadClick={handleUploadClick} />;
      case 'uploadFile':
        return <UploadFile onUploadComplete={handleFileUploadComplete} />;
      case 'salesData':
        return (
          <GenericTable
            title="Commission Data"
            data={SALES_DATA}
            columns={SALES_COLUMNS}
            pageConfig={{
              page: 0,
              setPage: () => {},
              rowsPerPage: 10,
              setRowsPerPage: () => {},
            }}
            isDashboard={true}
            isEdit={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      {step !== 'uploadData' ? (
        <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
          <Link
            href="#"
            underline="hover"
            color="#666666"
            onClick={() => setStep('uploadData')}
          >
            Upload Data
          </Link>
          {step !== 'uploadData' && (
            <Link
              href="#"
              underline="hover"
              color="#666666"
              onClick={() => setStep('uploadFile')}
            >
              Upload File
            </Link>
          )}
          {step === 'salesData' && (
            <Typography color="textPrimary">Commission Data</Typography>
          )}
        </Breadcrumbs>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Upload;
