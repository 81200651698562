import React from 'react';
import { Grid, Box } from '@mui/material';
import { Stats } from 'components/Stats';
import { Plans } from 'components/Plans';
import { Performance } from 'components/Performance';
import { SalesChart } from 'components/SalesChart';
import styles from './dashboard.module.scss';

const Dashboard = () => {
  return (
    <Box className={styles.dashboardContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stats />
        </Grid>
        <Grid item xs={12} md={6}>
          <SalesChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Plans />
        </Grid>
        <Grid item xs={12} md={12}>
          <Performance title={'best'} color={'#E1E8F8'} />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Performance title={'worst'} color={'#FF010124'} />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Dashboard;
