import React from 'react';

const Camera = () => {
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6907 2.20711L8.48628 5.51374H6.91871C3.45573 5.51374 0.648438 8.32103 0.648438 11.784V22.757C0.648438 26.22 3.45573 29.0273 6.91871 29.0273H25.7295C29.1925 29.0273 31.9998 26.22 31.9998 22.757V11.784C31.9998 8.32103 29.1925 5.51374 25.7295 5.51374H24.162L21.9575 2.20711C21.3761 1.33492 20.3972 0.811035 19.3489 0.811035H13.2993C12.251 0.811035 11.2722 1.33492 10.6907 2.20711ZM21.0268 17.2705C21.0268 19.8677 18.9213 21.9732 16.3241 21.9732C13.7269 21.9732 11.6214 19.8677 11.6214 17.2705C11.6214 14.6733 13.7269 12.5678 16.3241 12.5678C18.9213 12.5678 21.0268 14.6733 21.0268 17.2705ZM16.3241 7.08131C17.1899 7.08131 17.8917 6.37948 17.8917 5.51374C17.8917 4.64799 17.1899 3.94617 16.3241 3.94617C15.4584 3.94617 14.7565 4.64799 14.7565 5.51374C14.7565 6.37948 15.4584 7.08131 16.3241 7.08131Z"
        fill="#B35A41"
      />
      <rect
        x="11.6211"
        y="11.7842"
        width="10.8143"
        height="10.973"
        rx="5.40715"
        fill="white"
      />
      <path
        d="M18.7729 17.5484V16.9299H17.3715V15.4033H16.6904V16.9299H15.2812V17.5484H16.6904V19.1376H17.3715V17.5484H18.7729Z"
        fill="#B35A41"
      />
    </svg>
  );
};

export default Camera;
