import React, { useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { DATA } from 'utils/uploadData';
import { DATA_COLUMNS } from 'utils/uploadData';

const UploadData = ({ onUploadClick }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <GenericTable
      title="Data Upload"
      data={DATA}
      columns={DATA_COLUMNS}
      pageConfig={{ page, setPage, rowsPerPage, setRowsPerPage }}
      onUploadClick={onUploadClick}
      isMain={true}
      isUpload={true}
    />
  );
};

export default UploadData;
