import React from 'react';

const colFilter = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2499 5.2497L12.6121 5.24978C12.3021 4.37798 11.4782 3.75 10.5 3.75C9.52178 3.75 8.69775 4.37812 8.38777 5.25L3.74992 5.25022C3.3357 5.2503 2.99993 5.58607 3 6.0003C3.00008 6.41445 3.33585 6.7503 3.75 6.7503L8.38777 6.75008C8.69775 7.62203 9.52178 8.25 10.5 8.25C11.4783 8.25 12.3023 7.6218 12.6122 6.74985L14.25 6.74977C14.6642 6.7497 15 6.41392 14.9999 5.9997C14.9999 5.58547 14.6642 5.24962 14.2499 5.2497Z"
        fill="#464F60"
      />
      <path
        d="M14.2499 11.2497L9.61208 11.2499C9.30218 10.378 8.47815 9.75 7.49993 9.75C6.52163 9.75 5.6976 10.3782 5.3877 11.2502L3.74992 11.2502C3.3357 11.2503 2.99993 11.5861 3 12.0003C3.00008 12.4145 3.33585 12.7503 3.75 12.7503L5.38785 12.7502C5.69782 13.622 6.52178 14.25 7.49993 14.25C8.47815 14.25 9.30218 13.6219 9.61215 12.75L14.25 12.7498C14.6642 12.7497 15 12.4139 14.9999 11.9997C14.9999 11.5855 14.6641 11.2496 14.2499 11.2497Z"
        fill="#464F60"
      />
    </svg>
  );
};

export default colFilter;
