import React from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { REPORTS_DATA } from 'utils/reports';
import { REPORTS_COLUMNS } from 'utils/reports';

const Tracking = ({ handleTabChange, tab }) => {
  return (
    <GenericTable
      title="Payment Tracking"
      data={REPORTS_DATA}
      columns={REPORTS_COLUMNS}
      pageConfig={{
        page: 0,
        setPage: () => {},
        rowsPerPage: 10,
        setRowsPerPage: () => {},
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={false}
    />
  );
};

export default Tracking;
