export const SALES_DATA = [
  {
    id: 1,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 2,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 3,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 4,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 5,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 6,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 7,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
];

export const SALES_COLUMNS = [
  { headerName: 'Transaction ID', field: 'transactionID' },
  { headerName: 'Client Name', field: 'clientName' },
  { headerName: 'Premium', field: 'premium' },
  { headerName: 'Frequency', field: 'frequency' },
  { headerName: 'Gross FCI', field: 'grossFCI' },
  { headerName: 'FCI Recognition', field: 'FCIRecognition' },
  { headerName: 'Percentage Payable', field: 'percentagePayable' },
  { headerName: 'Payable', field: 'payable' },
  { headerName: 'Cash Type', field: 'cashType' },
  { headerName: 'Cash Match ID', field: 'cashMacthID' },
  { headerName: 'Payement Date', field: 'paymentDate' },
  { headerName: 'Provider', field: 'provider' },
  { headerName: 'Plan Type', field: 'planType' },
  { headerName: 'PLZ Number', field: 'plzNumber' },
  { headerName: 'Start Date', field: 'startDate' },
  { headerName: 'End Date', field: 'endDate' },
];
