import React from 'react';
import { Grid, Box } from '@mui/material';
import styles from './paymentRequests.module.scss';
import { PayRequest } from 'components/PayRequest';

const PaymentRequests = () => {
  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PayRequest />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PaymentRequests;
