import React from 'react';

const support = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.9C11.7009 1.9 13.3636 2.40438 14.7779 3.34936C16.1922 4.29434 17.2945 5.63748 17.9454 7.20892C18.5963 8.78037 18.7666 10.5095 18.4348 12.1778C18.1029 13.846 17.2839 15.3784 16.0811 16.5811C14.8784 17.7838 13.346 18.6029 11.6778 18.9347C10.0095 19.2666 8.28037 19.0963 6.70893 18.4454C5.13748 17.7944 3.79435 16.6922 2.84937 15.2779C1.90439 13.8636 1.4 12.2009 1.4 10.5C1.4 8.21914 2.30607 6.03169 3.91889 4.41888C5.5317 2.80607 7.71914 1.9 10 1.9ZM10 0.5C8.02219 0.5 6.08879 1.08649 4.4443 2.1853C2.79981 3.28412 1.51809 4.8459 0.761209 6.67316C0.00433288 8.50043 -0.1937 10.5111 0.192152 12.4509C0.578004 14.3907 1.53041 16.1725 2.92894 17.5711C4.32746 18.9696 6.10929 19.922 8.0491 20.3078C9.98891 20.6937 11.9996 20.4957 13.8268 19.7388C15.6541 18.9819 17.2159 17.7002 18.3147 16.0557C19.4135 14.4112 20 12.4778 20 10.5C20 7.84783 18.9464 5.3043 17.0711 3.42893C15.1957 1.55357 12.6522 0.5 10 0.5Z"
        fill={color}
      />
      <path
        d="M10.1575 5.5C10.5133 5.53716 10.8666 5.59526 11.2155 5.674C12.2815 5.976 12.9155 6.828 12.9955 7.96C13.0341 8.32272 12.9766 8.68921 12.8288 9.02269C12.681 9.35618 12.4482 9.64494 12.1535 9.86C11.8695 10.08 11.5715 10.28 11.2855 10.496C11.0783 10.6318 10.9104 10.8197 10.7988 11.0408C10.6872 11.262 10.6357 11.5086 10.6495 11.756C10.6566 11.9345 10.6038 12.1103 10.4994 12.2553C10.395 12.4002 10.2451 12.5061 10.0735 12.556C9.89089 12.6263 9.69009 12.634 9.50261 12.5779C9.31512 12.5218 9.15155 12.4051 9.03753 12.246C8.96282 12.1206 8.92215 11.9779 8.91953 11.832C8.90532 11.3799 9.00174 10.9311 9.20041 10.5247C9.39909 10.1183 9.694 9.76651 10.0595 9.5C10.3806 9.25568 10.6834 8.9883 10.9655 8.7C11.1 8.54154 11.1818 8.3451 11.1995 8.138C11.2455 7.572 10.8815 7.208 10.2955 7.17C9.58754 7.124 8.99154 7.308 8.57554 7.934C8.48874 8.08155 8.35883 8.19899 8.20329 8.2705C8.04776 8.34201 7.87405 8.36417 7.70553 8.334C7.10553 8.234 6.84754 7.746 7.09354 7.184C7.35106 6.63628 7.79376 6.19711 8.34354 5.944C8.90884 5.66618 9.52782 5.51467 10.1575 5.5Z"
        fill={color}
      />
      <path
        d="M10.7558 14.4761C10.7598 15.1181 10.3998 15.5001 9.76977 15.5001C9.13977 15.5001 8.71977 15.1321 8.71777 14.5941C8.71777 13.8481 9.05177 13.4761 9.71777 13.4561C10.2598 13.4401 10.8238 13.7721 10.7558 14.4761Z"
        fill={color}
      />
    </svg>
  );
};

export default support;
