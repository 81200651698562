import React from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import styles from './earlyPaymentRequest.module.scss';
import { GenericTable } from 'components/common/GenericTable';
import { EARLY_PAY_REQUEST_COLUMNS } from 'utils/requests';
import { EARLY_PAY_REQUEST_DATA } from 'utils/requests';
import { Close } from '@mui/icons-material';

const EarlyPaymentRequest = ({ handleClose }) => {
  return (
    <Box className={styles.earlyPaymentContainer}>
      <Box className={styles.heading}>
        <Typography variant="h6" className={styles.title}>
          Early Payment Request
        </Typography>
        <IconButton onClick={handleClose} className={styles.closeButton}>
          <Close />
        </IconButton>
      </Box>
      <Box className={styles.formContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Manager Name"
              variant="outlined"
              defaultValue="Henry Watt"
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Advisors ID"
              variant="outlined"
              defaultValue="ED 3456FRT"
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Total Commission Earned"
              variant="outlined"
              defaultValue="Total Commission earned"
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Reason For Early Payment"
              variant="outlined"
              defaultValue="Reason Early Payment"
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Request Date"
              variant="outlined"
              defaultValue="24-08-24"
              className={styles.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Admin Note"
              variant="outlined"
              className={styles.textField}
            />
          </Grid>
        </Grid>
        <Box className={styles.tableContainer}>
          <Typography variant="h6" className={styles.tableTitle}>
            Request History
          </Typography>
          <GenericTable
            title=""
            data={EARLY_PAY_REQUEST_DATA}
            columns={EARLY_PAY_REQUEST_COLUMNS}
            pageConfig={{
              page: 0,
              setPage: () => {},
              rowsPerPage: 10,
              setRowsPerPage: () => {},
            }}
            isTabs={false}
            isSearch={false}
          />
        </Box>
        <Box className={styles.actionButtons}>
          <Button variant="outlined" className={styles.rejectButton}>
            Reject
          </Button>
          <Button variant="contained" className={styles.acceptButton}>
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EarlyPaymentRequest;
