import React from 'react';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import styles from './plans.module.scss';
import { PLANS_DATA } from 'utils';
import Row from './Row/Row';
import Head from './Head/Head';
import Animation from 'utils/Animation';

const Plans = () => {
  return (
    <Card
      className={styles.plansCard}
      sx={{ boxShadow: 'none', borderRadius: '0.6rem' }}
    >
      <CardHeader
        title={
          <Animation>
            <Typography variant="h5" fontWeight="bold" className={styles.title}>
              Top Plans
            </Typography>
          </Animation>
        }
      />
      <TableContainer>
        <Table>
          <Head />
          <TableBody>
            {PLANS_DATA.map((plan, index) => (
              <Row plan={plan} key={index} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Plans;
