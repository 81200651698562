import React from 'react';

const paidIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0002 27.8574C7.92878 27.8574 2.14307 22.0716 2.14307 15.0002C2.14307 7.92878 7.92878 2.14307 15.0002 2.14307C22.0716 2.14307 27.8574 7.92878 27.8574 15.0002C27.8574 22.0716 22.0716 27.8574 15.0002 27.8574ZM15.0002 4.28592C9.10735 4.28592 4.28592 9.10735 4.28592 15.0002C4.28592 20.8931 9.10735 25.7145 15.0002 25.7145C20.8931 25.7145 25.7145 20.8931 25.7145 15.0002C25.7145 9.10735 20.8931 4.28592 15.0002 4.28592ZM15.0002 23.5716C14.3574 23.5716 13.9288 23.1431 13.9288 22.5002V22.3931C12.2145 21.9645 10.8216 20.5716 10.7145 18.3216C10.7145 17.6788 11.1431 17.2502 11.7859 17.2502C12.4288 17.2502 12.8574 17.6788 12.8574 18.3216C12.8574 19.9288 14.0359 20.4645 15.0002 20.4645C15.9645 20.4645 17.1431 19.9288 17.1431 18.3216C17.1431 17.1431 16.1788 16.1788 15.0002 16.1788C12.6431 16.1788 10.7145 14.2502 10.7145 11.8931C10.8216 9.53592 12.2145 8.14307 13.9288 7.71449V7.50021C13.9288 6.85735 14.3574 6.42878 15.0002 6.42878C15.6431 6.42878 16.0716 6.85735 16.0716 7.50021V7.60735C17.7859 8.03592 19.1788 9.42878 19.2859 11.6788C19.2859 12.3216 18.8574 12.7502 18.2145 12.7502C17.5716 12.7502 17.1431 12.3216 17.1431 11.6788C17.1431 10.0716 15.9645 9.53592 15.0002 9.53592C14.0359 9.53592 12.8574 10.0716 12.8574 11.6788C12.8574 12.8574 13.8216 13.8216 15.0002 13.8216C17.3574 13.8216 19.2859 15.7502 19.2859 18.1074C19.1788 20.4645 17.7859 21.8574 16.0716 22.2859V22.5002C16.0716 23.1431 15.6431 23.5716 15.0002 23.5716Z"
        fill="#FFCE00"
      />
    </svg>
  );
};

export default paidIcon;
