import React, { useState } from 'react';
import { TextField, Button, Link, Box } from '@mui/material';
import styles from './signupForm.module.scss';
import { VerificationCode } from 'components/VerificationCode';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { useDispatch } from 'react-redux';
import { REGISTER_USER } from '_redux/types/auth.types';

const SignupForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRegister = event => {
    event.preventDefault();

    const payload = {
      firstName: event.target.firstName.value,
      lastName: event.target.lastName.value,
      email: event.target.email.value,
      phoneNo: event.target.phone.value,
      password: event.target.password.value,
      onSuccess: () => {
        setEmail(event.target.email.value);
        handleOpen();
      },
    };

    dispatch({ type: REGISTER_USER, payload });
  };

  return (
    <Box className={styles.formContainer}>
      <h2>Create an account</h2>
      <form className={styles.form} onSubmit={handleRegister}>
        <TextField
          label="First Name"
          name="firstName"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Last Name"
          name="lastName"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email Address"
          name="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Phone Number"
          name="phone"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />

        <p className={styles.terms}>
          By continuing, you agree to Paycheck's{' '}
          <Link href="#">Terms & Conditions</Link> and{' '}
          <Link href="#">Privacy Policy</Link>.
        </p>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className={styles.submitButton}
        >
          Sign In
        </Button>

        <p className={styles.loginLink}>
          Already a member? <Link href="/login">Login</Link>
        </p>
      </form>
      <ModalContainer open={open}>
        <VerificationCode email={email} onClose={handleClose} />
      </ModalContainer>
    </Box>
  );
};

export default SignupForm;
