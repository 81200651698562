import React, { useState } from 'react';
import {
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styles from './notificationPopover.module.scss';

const NotificationsPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const notifications = [
    {
      avatar: 'https://i.pravatar.cc/150?img=1',
      message: 'Dennis Nedry commented on Isla Nublar SOC2 compliance report',
      time: 'Last Wednesday at 9:42 AM',
      backgroundColor: '#F4F7FCBF', // Viewed notification
    },
    {
      avatar: 'https://i.pravatar.cc/150?img=2',
      message: 'Dennis Nedry commented on Isla Nublar SOC2 compliance report',
      time: 'Last Wednesday at 9:42 AM',
      backgroundColor: '#F1F1F1BF', // Unviewed notification
    },
    {
      avatar: 'https://i.pravatar.cc/150?img=3',
      message: 'Dennis Nedry commented on Isla Nublar SOC2 compliance report',
      time: 'Last Wednesday at 9:42 AM',
      backgroundColor: '#F4F7FCBF', // Viewed notification
    },
    {
      avatar: 'https://i.pravatar.cc/150?img=4',
      message: 'Dennis Nedry commented on Isla Nublar SOC2 compliance report',
      time: 'Last Wednesday at 9:42 AM',
      backgroundColor: '#F1F1F1BF', // Unviewed notification
    },
  ];

  return (
    <>
      <IconButton className={styles.iconButton} onClick={handleClick}>
        <NotificationsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={styles.popover}
      >
        <Box p={2}>
          <Typography variant="h6" className={styles.popoverTitle}>
            Notifications
          </Typography>
          <List className={styles.notificationList}>
            {notifications.map((notification, index) => (
              <ListItem
                key={index}
                className={styles.notificationItem}
                style={{ background: notification.backgroundColor }}
              >
                <Avatar
                  src={notification.avatar}
                  alt="User Avatar"
                  className={styles.avatar}
                />
                <ListItemText
                  primary={
                    <Typography className={styles.notificationText}>
                      {notification.message}
                    </Typography>
                  }
                  secondary={
                    <Typography className={styles.notificationTime}>
                      {notification.time}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
