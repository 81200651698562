import React from 'react';
import Greeting from './Greeting/Greeting';
import SearchBar from './SearchBar/SearchBar';
import IconButtons from './IconButtons/IconButtons';
import Avatar from './Avatar/Avatar';
import styles from './header.module.scss';
import profileImage from 'assets/images/profile.png';

const Header = () => {
  return (
    <div className={styles.headerContainer}>
      <Greeting name="Smith" />
      <div className={styles.iconContainer}>
        <SearchBar />
        <IconButtons />
        <Avatar profileImage={profileImage} />
      </div>
    </div>
  );
};

export default Header;
