import React from 'react';
import styles from './avatar.module.scss';

const Avatar = ({ profileImage }) => {
  return (
    <div className={styles.avatar}>
      <img src={profileImage} alt="User Avatar" />
    </div>
  );
};

export default Avatar;
