import React from 'react';

const Dasboard = ({ color = '#BBBBBB' }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.25H1.5C1.10218 0.25 0.720644 0.408035 0.43934 0.68934C0.158035 0.970644 0 1.35218 0 1.75V6.25C0 6.64782 0.158035 7.02936 0.43934 7.31066C0.720644 7.59196 1.10218 7.75 1.5 7.75H6C6.39782 7.75 6.77936 7.59196 7.06066 7.31066C7.34196 7.02936 7.5 6.64782 7.5 6.25V1.75C7.5 1.35218 7.34196 0.970644 7.06066 0.68934C6.77936 0.408035 6.39782 0.25 6 0.25ZM6 6.25H1.5V1.75H6V6.25ZM15 0.25H10.5C10.1022 0.25 9.72064 0.408035 9.43934 0.68934C9.15804 0.970644 9 1.35218 9 1.75V6.25C9 6.64782 9.15804 7.02936 9.43934 7.31066C9.72064 7.59196 10.1022 7.75 10.5 7.75H15C15.3978 7.75 15.7794 7.59196 16.0607 7.31066C16.342 7.02936 16.5 6.64782 16.5 6.25V1.75C16.5 1.35218 16.342 0.970644 16.0607 0.68934C15.7794 0.408035 15.3978 0.25 15 0.25ZM15 6.25H10.5V1.75H15V6.25ZM6 9.25H1.5C1.10218 9.25 0.720644 9.40804 0.43934 9.68934C0.158035 9.97064 0 10.3522 0 10.75V15.25C0 15.6478 0.158035 16.0294 0.43934 16.3107C0.720644 16.592 1.10218 16.75 1.5 16.75H6C6.39782 16.75 6.77936 16.592 7.06066 16.3107C7.34196 16.0294 7.5 15.6478 7.5 15.25V10.75C7.5 10.3522 7.34196 9.97064 7.06066 9.68934C6.77936 9.40804 6.39782 9.25 6 9.25ZM6 15.25H1.5V10.75H6V15.25ZM15 9.25H10.5C10.1022 9.25 9.72064 9.40804 9.43934 9.68934C9.15804 9.97064 9 10.3522 9 10.75V15.25C9 15.6478 9.15804 16.0294 9.43934 16.3107C9.72064 16.592 10.1022 16.75 10.5 16.75H15C15.3978 16.75 15.7794 16.592 16.0607 16.3107C16.342 16.0294 16.5 15.6478 16.5 15.25V10.75C16.5 10.3522 16.342 9.97064 16.0607 9.68934C15.7794 9.40804 15.3978 9.25 15 9.25ZM15 15.25H10.5V10.75H15V15.25Z"
        fill={color}
      />
    </svg>
  );
};

export default Dasboard;
