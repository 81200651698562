import { Button } from '@mui/material';
import { chip } from './styles';

export const ERRORS_DATA = [
  {
    id: 1,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Pending',
    comments: 'N/A',
  },
  {
    id: 2,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Approved',
    comments: 'N/A',
  },
  {
    id: 3,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Pending',
    comments: 'N/A',
  },
  {
    id: 4,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Approved',
    comments: 'N/A',
  },
  {
    id: 5,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Pending',
    comments: 'N/A',
  },
  {
    id: 6,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Approved',
    comments: 'N/A',
  },
  {
    id: 7,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Pending',
    comments: 'N/A',
  },
  {
    id: 8,
    errorID: '001',
    transactionID: '5648256326',
    errorDescription: 'Missing Payment Date',
    dateDetected: '25-05-24',
    status: 'Approved',
    comments: 'N/A',
  },
];

export const ERRORS_COLUMNS = [
  { headerName: 'Error ID', field: 'errorID' },
  { headerName: 'Transaction ID', field: 'transactionID' },
  { headerName: 'Error Description', field: 'errorDescription' },
  { headerName: 'Date Detected', field: 'dateDetected' },
  {
    headerName: 'Status',
    field: 'status',
    renderCell: row => (
      <Button variant="outlined" size="small" sx={chip(row)}>
        {row.status}
      </Button>
    ),
  },
  { headerName: 'Comments', field: 'comments' },
];

export const VALIDATIONS_DATA = [
  {
    id: 1,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 2,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 3,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 4,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 5,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 6,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 7,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
  {
    id: 8,
    transactionID: '5648256326',
    date: '25-05-24',
    clientName: 'William',
    productService: 'Pension',
    salesAmount: '£120.00',
    commissionRate: '20%',
    grossCommission: '£17.00',
    idRef: 'KP374',
    grossFCI: '£110.00',
  },
];

export const VALIDATIONS_COLUMNS = [
  { headerName: 'Transaction ID', field: 'transactionID' },
  { headerName: 'Date', field: 'date' },
  { headerName: 'Client Name', field: 'clientName' },
  { headerName: 'Product / Service', field: 'productService' },
  { headerName: 'Commission Amount', field: 'salesAmount' },
  { headerName: 'Commission Rate', field: 'commissionRate' },
  { headerName: 'Gross Commission', field: 'grossCommission' },
  { headerName: 'IDRef', field: 'idRef' },
  { headerName: 'Gross FCI', field: 'grossFCI' },
];
