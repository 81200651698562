import React from 'react';

const Upload = () => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4548 47.0547H13.7833C6.63017 46.5437 3.4187 41.0451 3.4187 36.1547C3.4187 31.2644 6.6302 25.7414 13.6616 25.2548C14.6592 25.1575 15.535 25.936 15.608 26.9579C15.681 27.9555 14.9269 28.8313 13.905 28.9043C9.18496 29.245 7.06824 32.7972 7.06824 36.1791C7.06824 39.561 9.18496 43.1132 13.905 43.4538H18.4548C19.4523 43.4538 20.2795 44.281 20.2795 45.2786C20.2795 46.2761 19.4523 47.0547 18.4548 47.0547Z"
        fill="#353535"
      />
      <path
        d="M40.8619 47.0545C40.8132 47.0545 40.7889 47.0545 40.7402 47.0545C39.7427 47.0545 38.8182 46.2272 38.8182 45.2297C38.8182 44.1835 39.5968 43.4049 40.6186 43.4049C43.6112 43.4049 46.2876 42.3587 48.38 40.4853C52.1755 37.1764 52.4188 32.4077 51.3969 29.0501C50.375 25.7169 47.5284 21.897 42.565 21.2888C41.7621 21.1914 41.1295 20.5832 40.9835 19.7803C40.0103 13.941 36.8718 9.90221 32.103 8.44239C27.1883 6.90959 21.4463 8.41806 17.8698 12.1649C14.3905 15.7901 13.5877 20.8752 15.6071 26.4711C15.9477 27.42 15.4612 28.4662 14.5123 28.8068C13.5634 29.1474 12.5172 28.6608 12.1766 27.7119C9.71922 20.8508 10.8384 14.2816 15.2422 9.6589C19.7433 4.93884 26.9694 3.06539 33.1736 4.96315C38.8669 6.71492 42.8813 11.4107 44.3411 17.9312C49.3044 19.0504 53.2946 22.8216 54.8761 28.0282C56.6036 33.6972 55.0464 39.5364 50.7886 43.2346C48.088 45.6677 44.5601 47.0545 40.8619 47.0545Z"
        fill="#353535"
      />
      <path
        d="M29.4999 54.2078C24.6095 54.2078 20.0355 51.6045 17.5295 47.3953C17.2618 46.9817 16.9942 46.4951 16.7752 45.9599C15.948 44.2324 15.51 42.2617 15.51 40.2179C15.51 32.5052 21.7872 26.228 29.4999 26.228C37.2126 26.228 43.4898 32.5052 43.4898 40.2179C43.4898 42.286 43.0519 44.2324 42.176 46.0328C41.9814 46.4951 41.7137 46.9817 41.4218 47.444C38.9644 51.6045 34.3903 54.2078 29.4999 54.2078ZM29.4999 29.8776C23.8066 29.8776 19.1595 34.5246 19.1595 40.2179C19.1595 41.7264 19.4759 43.1375 20.0841 44.427C20.2787 44.8407 20.449 45.1813 20.6437 45.4976C22.4928 48.6362 25.8747 50.5583 29.4755 50.5583C33.0764 50.5583 36.4583 48.6362 38.2831 45.5462C38.5021 45.1813 38.6968 44.8406 38.8428 44.5C39.4997 43.1619 39.8159 41.7507 39.8159 40.2422C39.8402 34.5246 35.1932 29.8776 29.4999 29.8776Z"
        fill="#353535"
      />
      <path
        d="M28.1129 44.4511C27.6507 44.4511 27.1885 44.2808 26.8235 43.9159L24.4147 41.5072C23.7091 40.8016 23.7091 39.6337 24.4147 38.9282C25.1203 38.2226 26.2881 38.2226 26.9937 38.9282L28.1616 40.096L32.0544 36.4951C32.8087 35.8139 33.9522 35.8625 34.6334 36.5924C35.3147 37.3223 35.2661 38.4902 34.5361 39.1715L29.3538 43.9645C28.9888 44.2808 28.5509 44.4511 28.1129 44.4511Z"
        fill="#353535"
      />
    </svg>
  );
};

export default Upload;
