import React from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
} from '@mui/material';
import styles from './settings.module.scss';
import { Camera } from 'assets/svgs';
import { Title } from 'components/common/Title';
import { Tabs } from 'components/common/Tabs';
import SettingsAnimations from 'components/common/Animations/SettingsAnimations';

const Settings = ({ tab, tabs, handleTabChange }) => {
  return (
    <Box className={styles.settingsContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Title title="Profile Settings" />
      </Box>
      <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      <SettingsAnimations type="fadeInUp" duration={0.6}>
        <Box className={styles.formContainer}>
          <Box className={styles.profileHeader}>
            <Box className={styles.avatarContainer}>
              <Avatar
                src="https://i.pravatar.cc/300"
                alt="Profile Picture"
                className={styles.avatar}
              />
              <IconButton className={styles.cameraIcon}>
                <Camera />
              </IconButton>
            </Box>
            <Typography variant="h6" className={styles.userName}>
              William Smith
            </Typography>
            <Typography variant="body2" className={styles.userId}>
              ID #4656DER45
            </Typography>
          </Box>

          <Grid container spacing={3} className={styles.formFields}>
            <Grid item xs={12} md={6}>
              <SettingsAnimations type="fadeInLeft" delay={0.2}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  defaultValue="William"
                  className={styles.textField}
                />
              </SettingsAnimations>
            </Grid>
            <Grid item xs={12} md={6}>
              <SettingsAnimations type="fadeInRight" delay={0.3}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  defaultValue="Smith"
                  className={styles.textField}
                />
              </SettingsAnimations>
            </Grid>
            <Grid item xs={12} md={6}>
              <SettingsAnimations type="fadeInLeft" delay={0.4}>
                <TextField
                  fullWidth
                  label="Advisors ID"
                  variant="outlined"
                  defaultValue="EV4546TFD"
                  className={styles.textField}
                />
              </SettingsAnimations>
            </Grid>
            <Grid item xs={12} md={6}>
              <SettingsAnimations type="fadeInRight" delay={0.5}>
                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  defaultValue="House 345 Lane 43 Royal Gate, UK"
                  className={styles.textField}
                />
              </SettingsAnimations>
            </Grid>
            <Grid item xs={12} md={6}>
              <SettingsAnimations type="fadeInLeft" delay={0.6}>
                <TextField
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  defaultValue="william.smith@example.com"
                  className={styles.textField}
                />
              </SettingsAnimations>
            </Grid>
            <Grid item xs={12} md={6}>
              <SettingsAnimations type="fadeInRight" delay={0.7}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  defaultValue="+44 1234 567890"
                  className={styles.textField}
                />
              </SettingsAnimations>
            </Grid>
          </Grid>

          <Box className={styles.actionButtons}>
            <SettingsAnimations type="fadeInLeft" delay={0.8}>
              <Button variant="outlined" className={styles.cancelButton}>
                Cancel
              </Button>
            </SettingsAnimations>
            <SettingsAnimations type="fadeInRight" delay={0.9}>
              <Button variant="contained" className={styles.saveButton}>
                Save Changes
              </Button>
            </SettingsAnimations>
          </Box>
        </Box>
      </SettingsAnimations>
    </Box>
  );
};

export default Settings;
