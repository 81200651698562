import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import styles from './mails.module.scss';
import { EMails } from 'components/Mails';
import ViewMail from 'components/ViewMail/ViewMail';

const Mails = () => {
  const [tab, setTab] = useState(0);
  const [view, setView] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabs = [{ label: 'Inbox' }, { label: 'Sent' }];

  const renderContent = () => {
    switch (tab) {
      case 0:
        return (
          <EMails
            handleTabChange={handleTabChange}
            tab={tab}
            tabs={tabs}
            setView={setView}
          />
        );
      case 1:
        return (
          <EMails
            handleTabChange={handleTabChange}
            tab={tab}
            tabs={tabs}
            setView={setView}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.dashboardContainer}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {view ? (
              <ViewMail onBack={() => setView(false)} />
            ) : (
              renderContent()
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Mails;
