import { Avatar, Box, TableRow, Typography } from '@mui/material';
import React from 'react';
import styles from '../performance.module.scss';
import LeftToRight from 'utils/LeftToRight';

const Row = ({ employee, index, color }) => {
  return (
    <TableRow key={index}>
      <LeftToRight index={index}>
        <Box
          className={styles.employeeBox}
          sx={{
            backgroundColor: color || '#ffffff',
          }}
        >
          <Box className={styles.employeeInfo}>
            <Avatar
              src={employee.avatar}
              alt={employee.name}
              className={styles.avatar}
            />
            <Box>
              <Typography variant="h6" className={styles.employeeName}>
                {employee.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {employee.email}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.employeePlan}>
            <Typography variant="h6" className={styles.employeeName}>
              Selling plan
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {employee.plan}
            </Typography>
          </Box>
        </Box>
      </LeftToRight>
    </TableRow>
  );
};

export default Row;
