import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Box } from '@mui/material';
import styles from './salesChart.module.scss';
import { CHART_OPTIONS } from 'utils';
import { CHART_SERIES } from 'utils';
import CardHeader from './CardHeader/CardHeader';

const SalesChart = () => {
  const [seriesData, setSeriesData] = useState('Weekly');

  const handleChangeSeries = event => {
    setSeriesData(event.target.value);
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 0 }} className={styles.card}>
      <CardHeader
        seriesData={seriesData}
        handleChangeSeries={handleChangeSeries}
      />
      <Box>
        <Chart
          options={CHART_OPTIONS}
          series={CHART_SERIES}
          type="area"
          height={308}
        />
      </Box>
    </Card>
  );
};

export default SalesChart;
