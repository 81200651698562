import React, { useState } from 'react';
import {
  TextField,
  Button,
  Link,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import styles from './loginForm.module.scss';
import { useDispatch } from 'react-redux';
import { LOGIN_USER } from '_redux/types/auth.types';
import { useNavigate } from 'react-router-dom';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import { VerificationCode } from 'components/VerificationCode';
import { NewPassword } from 'components/NewPassword';

const LoginForm = () => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const handleLogin = event => {
    event.preventDefault();
    dispatch({
      type: LOGIN_USER,
      payload: {
        email,
        password,
        onSuccess: () => navigate('/'),
      },
    });
  };

  const onEmailSubmit = email => {
    setEmail(email);
    setStep(2);
  };

  const onOtpVerified = () => setStep(3);

  return (
    <Box className={styles.formContainer}>
      <h2>Login to your account</h2>
      <form className={styles.form} onSubmit={handleLogin}>
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Box className={styles.passwordOptions}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
          />
          <Link className={styles.forgotPassword} onClick={() => setOpen(true)}>
            Forgot Password?
          </Link>
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
        <p>
          Not registered yet? <Link href="/signup">Create an account</Link>
        </p>
      </form>

      <ModalContainer open={open}>
        {step === 1 && (
          <ForgotPassword
            email={email}
            onSubmit={onEmailSubmit}
            onClose={handleClose}
          />
        )}
        {step === 2 && (
          <VerificationCode
            email={email}
            onVerified={onOtpVerified}
            onClose={handleClose}
            isPassword={true}
          />
        )}
        {step === 3 && <NewPassword email={email} onClose={handleClose} />}
      </ModalContainer>
    </Box>
  );
};

export default LoginForm;
