import React from 'react';
import { Box } from '@mui/material';
import { Logo } from 'assets/svgs';
import styles from './signUpInfo.module.scss';

const SignUpInfo = () => {
  return (
    <Box className={styles.logoContainer}>
      <Logo />
      <h1 className={styles.title}>Lorem ipsum dolor sit amet consectetur.</h1>
      <p className={styles.description}>
        Lorem ipsum dolor sit amet consectetur. Gravida ornare nulla fames
        ullamcorper aliquam porttitor ut nibh tempor.
      </p>
    </Box>
  );
};

export default SignUpInfo;
