import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-hot-toast';
import { loginService, registerService } from '_redux/services/auth.service';
import { authActions } from '_redux/slices/auth.slice';
import { LOG_OUT, REGISTER_USER, LOGIN_USER } from '_redux/types/auth.types';
import { VERIFY_OTP } from '_redux/types/auth.types';
import { RESEND_OTP } from '_redux/types/auth.types';
import { resendOtpService } from '_redux/services/auth.service';
import { verifyOtpService } from '_redux/services/auth.service';
import { CHANGE_PASSWORD } from '_redux/types/auth.types';
import { changePasswordService } from '_redux/services/auth.service';

export function* loginSaga(action) {
  try {
    const response = yield call(loginService, action.payload);

    if (response?.data?.accessToken) {
      yield put(
        authActions.actions.setUserSlice({
          user: response.data,
          accessToken: response.data.accessToken,
        })
      );
      toast.success('Login successful!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.response?.data?.message || 'Login failed. Please try again.';
    toast.error(errorMessage);
  }
}

export function* registerSaga(action) {
  try {
    const user = yield call(registerService, action.payload);

    if (user) {
      yield put(authActions.actions.setUserSlice(user));

      toast.success('Registration successful!');

      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.response?.data?.message || 'Registration failed. Please try again.';
    toast.error(errorMessage);
  }
}

export function* logoutSaga() {
  try {
    yield put(authActions.actions.resetAuth());
    toast.success('Logged out successfully.');
  } catch (err) {
    console.log('Logout error', err);
  }
}

export function* verifyOtpSaga(action) {
  try {
    const response = yield call(verifyOtpService, action.payload);
    if (response?.status === 200) {
      toast.success('OTP Verified successfully!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage = err?.response?.data?.message || 'Verification failed';
    toast.error(errorMessage);
  }
}

// export function* resendOtpSaga(action) {
//   try {
//     const response = yield call(resendOtpService, action.payload);
//     if (response?.status === 200) {
//       toast.success('OTP sent successfully!');
//       if (action.payload.onSuccess) {
//         action.payload.onSuccess();
//       }
//     }
//   } catch (err) {
//     const errorMessage = err?.response?.data?.message || 'Resend failed';
//     toast.error(errorMessage);
//   }
// }
export function* resendOtpSaga(action) {
  try {
    // Use toast.promise to handle loading, success, and error messages
    yield toast
      .promise(
        resendOtpService(action.payload), // Call the resend OTP service
        {
          loading: 'Sending OTP...',
          success: 'OTP sent successfully!',
          error: 'Failed to send OTP, please try again.',
        }
      )
      .then(response => {
        if (response?.status === 200) {
          // If the OTP was sent successfully, you can trigger further actions here
          if (action.payload.onSuccess) {
            action.payload.onSuccess(); // Execute the success callback if provided
          }
        }
      })
      .catch(error => {
        console.log('Error sending OTP:', error); // Handle any errors
      });
  } catch (error) {
    console.error('Saga error during OTP resend:', error); // Handle errors outside of promise
  }
}

export function* changePasswordSaga(action) {
  try {
    const response = yield call(changePasswordService, action.payload);

    if (response?.status === 200) {
      toast.success('Password changed successfully!');
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.response?.data?.message ||
      'Password change failed. Please try again.';
    toast.error(errorMessage);
  }
}

export function* watchAuthAsync() {
  yield takeEvery(LOGIN_USER, loginSaga);
  yield takeEvery(REGISTER_USER, registerSaga);
  yield takeEvery(LOG_OUT, logoutSaga);
  yield takeEvery(VERIFY_OTP, verifyOtpSaga);
  yield takeEvery(RESEND_OTP, resendOtpSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}
