import React, { useState } from 'react';
import { Box, List, Button } from '@mui/material';
import styles from './mails.module.scss';
import { EMAILS_DATA } from 'utils/mails';
import { Tabs } from 'components/common/Tabs';
import { Title } from 'components/common/Title';
import Animation from 'utils/Animation';
import { Edit } from '@mui/icons-material';
import Item from '../Item/Item';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import ComposeMail from 'components/ComposeMail/ComposeMail';

const Mails = ({ handleTabChange, tab, tabs, setView }) => {
  const [selectedMails, setSelectedMails] = useState([]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggle = index => {
    const currentIndex = selectedMails.indexOf(index);
    const newSelectedMails = [...selectedMails];

    if (currentIndex === -1) {
      newSelectedMails.push(index);
    } else {
      newSelectedMails.splice(currentIndex, 1);
    }

    setSelectedMails(newSelectedMails);
  };

  const handleDelete = index => {
    console.log(`Delete mail at index ${index}`);
  };

  const handleView = index => {
    setView(true);
    console.log(EMAILS_DATA[index]);
  };

  return (
    <Box className={styles.mailContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Title title="Emails" />
        <Animation>
          <Button
            variant="contained"
            startIcon={<Edit />}
            sx={{
              textTransform: 'none',
              borderRadius: '8px',
              backgroundColor: '#003366',
              '&:hover': {
                backgroundColor: '#002244',
              },
            }}
            onClick={handleOpen}
          >
            Compose
          </Button>
        </Animation>
      </Box>
      <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      <List>
        {EMAILS_DATA.map((email, index) => {
          const isSelected = selectedMails.indexOf(index) !== -1;
          return (
            <Item
              isSelected={isSelected}
              email={email}
              index={index}
              handleDelete={handleDelete}
              handleToggle={handleToggle}
              handleView={handleView}
              key={index}
            />
          );
        })}
      </List>
      <ModalContainer open={open}>
        <ComposeMail onClose={handleClose} />
      </ModalContainer>
    </Box>
  );
};

export default Mails;
